import React from 'react';
import {HyperwebContext} from '../../contexts/HyperwebContext';
import HyperwebTextEditorPopup from './HyperwebTextEditorPopup';
import HyperwebImagePopup from './HyperwebImagePopup';

export default class HyperwebPopups extends React.Component {
  renderTextEditors(){
    return this.context.textEditorPopups.map((popup)=>{
      return (
        <HyperwebTextEditorPopup
          key={`textEditor-${popup.id}`}
          popup={popup} />
      );
    })
  }
  renderImages(){
    return this.context.imagePopups.map((popup)=>{
      return (
        <HyperwebImagePopup
          key={`image-${popup.id}`}
          popup={popup} />
      );
    })
  }
  render() {
    return (
      <div className='hyperweb--popups'>
        {this.renderTextEditors()}
        {this.renderImages()}
      </div>
    );
  }
}

HyperwebPopups.contextType = HyperwebContext;