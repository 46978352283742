import React from 'react';

export default class HyperwebAdCookies extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      flipped: false
    }
  }
  handleClick() {
    this.setState({
      flipped: !this.state.flipped
    });
  }
  render() {
    const flippedClass = this.state.flipped ? 'hyperwebAd--cookies--flipped' : 'hyperwebAd--cookies--notFlipped';
    return (

      <div className={`hyperwebAd--cookies ${flippedClass}`}>
        <div className='hyperwebAd--cookies--title'>
          Cookies & Privacy
        </div>
        <div className='hyperwebAd--cookies--message'>
          This site does not use cookies to ensure you get the best experience. Sorry!
        </div>
        <div className='hyperwebAd--cookies--btns'>
          <div 
            onClick={()=>{
              this.handleClick()
            }}
            className='hyperwebAd--cookies--btn'>
            Accept
          </div>
          <div 
            onClick={()=>{
              this.handleClick()
            }}
            className='hyperwebAd--cookies--btn'>
            Agree
          </div>
        </div>
      </div>
    );
  }
}