import React from 'react';

export default class HyperwebLoader extends React.Component {

  render() {
    return (
      <div className='hyperweb--loader'>
        <div className='hyperweb--loader--bar'>
          <div className='hyperweb--loader--progressBar'></div>
          <div className='hyperweb--loader--barText'>
            HyperWeb 
          </div>
          <div className='hyperweb--loader--progressCover'></div>

          <div className='hyperweb--loader--barBevelTop'></div>
          <div className='hyperweb--loader--barBevelRight'></div>
          <div className='hyperweb--loader--barBevelBottom'></div>
          <div className='hyperweb--loader--barBevelLeft'></div>
        </div>
      </div>
    );
  }
}