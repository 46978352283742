import raw from "raw.macro";

const mountains1 = raw("./hyperwebAscii/mountains1.md");
const mountains2 = raw("./hyperwebAscii/mountains2.md");
const desert1 = raw("./hyperwebAscii/desert1.md");
const desert2 = raw("./hyperwebAscii/desert2.md");
const island1 = raw("./hyperwebAscii/island1.md");
const island2 = raw("./hyperwebAscii/island2.md");
const clouds = raw("./hyperwebAscii/clouds.md");
const rushmore = raw("./hyperwebAscii/rushmore.md");
const paris = raw("./hyperwebAscii/paris.md");
const mushroom1 = raw("./hyperwebAscii/mushroom1.md");
const mushroom2 = raw("./hyperwebAscii/mushroom2.md");
const mushroom3 = raw("./hyperwebAscii/mushroom3.md");
const flower1 = raw("./hyperwebAscii/flower1.md");
const flower2 = raw("./hyperwebAscii/flower2.md");
const rose = raw("./hyperwebAscii/rose.md");
const russia = raw("./hyperwebAscii/russia.md");
const computerLovers = raw("./hyperwebAscii/computerLovers.md");
const computer = raw("./hyperwebAscii/computer.md");


const HyperwebAsciiImages = [
  {
    name: 'mountains1',
    content: mountains1
  },
  {
    name: 'mountains2',
    content: mountains2
  },
  {
    name: 'desert1',
    content: desert1
  },
  {
    name: 'desert2',
    content: desert2
  },
  {
    name: 'island1',
    content: island1
  },
  {
    name: 'island2',
    content: island2
  },
  {
    name: 'clouds',
    content: clouds
  },
  {
    name: 'rushmore',
    content: rushmore
  },
  {
    name: 'paris',
    content: paris
  },
  {
    name: 'russia',
    content: russia
  },
  {
    name: 'mushroom1',
    content: mushroom1
  },
  {
    name: 'mushroom2',
    content: mushroom2
  },
  {
    name: 'mushroom3',
    content: mushroom3
  },
  {
    name: 'flower1',
    content: flower1
  },
  {
    name: 'flower2',
    content: flower2
  },
  {
    name: 'rose',
    content: rose
  },
  {
    name: 'computerLovers',
    content: computerLovers
  },
  {
    name: 'computer',
    content: computer
  }
]



export {
  HyperwebAsciiImages
};