import React from 'react';
import Guid from 'guid';
import Calc from '../../utils/Calc';

export default class HyperwebAdCongratsPwned extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      active: true,
      popups: [this.initPopup()]
    }
  }
  componentDidMount() {
    this.queuePopup();

    setTimeout(()=>{
      this.setState({
        active: false
      });
    }, 30*1000)
  }
  queuePopup() {
    setTimeout(()=>{
      this.setState({
        popups: this.state.popups.concat([this.initPopup()])
      });
      if (this.state.active) {
        this.queuePopup();
      }
    }, 1500);
  }
  handleClick(popup) {
    this.setState({
      popups: this.state.popups.filter((p)=>{
        return p.id !== popup.id
      })
    });
  }
  initPopup() {
    return {
      id: Guid.create(),
      top: Calc.randomIntBetween(1,80),
      left: Calc.randomIntBetween(1,80)
    }
  }
  render() {
    return this.state.popups.map((popup)=>{
      return (
        <div 
          key={popup.id}
          style={{
            top: `${popup.top}%`,
            left: `${popup.left}%`
          }}
          onClick={()=>{this.handleClick(popup)}}
          className='hyperwebAd--congrats--pwned'>
          All your fonts are belong to us! HAHAHAHAHAHA
        </div>
      );
    });
  }
}