import React from 'react';
import HomeTop from './HomeTop';
import HomeNavItem from './HomeNavItem';
import Info from '../Info/Info';
import FontIndex from '../FontIndex/FontIndex';

export default class Home extends React.Component {
  render() {
    return (
      <div 
        data-ignore-inspector={true}
        className='home'>
        <HomeTop />
        <nav>
          <div className='home--navItem home--navItemLogo'>
            <span>HyperText</span>
          </div>
          <HomeNavItem 
            key='hyperfood'
            href='/hyperfood'
            hoverText='Module 1: HyperFood'
            title='Module 01: HyperFood'
            history={this.props.history} />
          <HomeNavItem 
            key='hyperweb'
            href='/hyperweb'
            title='Module 02: HyperWeb'
            history={this.props.history} />
          <HomeNavItem 
            href='/hyperage'
            title='Module 03: HyperAge'
            history={this.props.history} />
          <HomeNavItem 
            href='/font-index'
            title='Font Index'
            domLoaded={this.props.domLoaded}
            history={this.props.history}>
            <FontIndex
              domLoaded={this.props.domLoaded} />
          </HomeNavItem>
          <HomeNavItem 
            href='/info'
            title='Info'
            domLoaded={this.props.domLoaded}
            hoverText='Information'
            history={this.props.history}>
            <Info />
          </HomeNavItem>
        </nav>
      </div>
    );
  }
}