import React from 'react';
import HyperfoodMenuItem from './HyperfoodMenuItem';
import {HyperfoodContext, HyperfoodContextMenu} from '../../contexts/HyperfoodContext';
import HyperfoodDblOutlinedText from './HyperfoodDblOutlinedText';
import SideScroller from '../Scrollers/SideScroller';

export default class HyperfoodMenu extends React.Component {
  renderMenuItems() {
    return HyperfoodContextMenu.map((item, i)=>{
      return (
        <HyperfoodMenuItem
          key={i}
          item={item}
          size={item.size}
          onItemClick={()=>{this.context.onAddItem(item)}} />
      );
    })
  }
  render() {
    return (
      <div className='hyperfood--menu'>
        <div className='hyperfood--menuIntro'>
          <div className='hyperfood--menuIntroTextWrapper'>
            <HyperfoodDblOutlinedText
              className='hyperfood--menuIntroText'
              font='Obviously VF'
              text='Clearance 9ft.' />
          </div>
        </div>
        <div className='hyperfood--menuMain'>
          <SideScroller 
            className='hyperfood--menuScroller'
            text='May I take your order?' />
          <div className='hyperfood--menuBoardSection'>
            <div className='hyperfood--menuBoard'>
              <div className='hyperfood--menuItems'>
                {this.renderMenuItems()}
              </div>
            </div>
          </div>
          <div className='hyperfood--menuThankYou'>
            <div className='hyperfood--menuThankYou-1'>
              <span>Thank You!</span>
              <span>Thank You!</span>
            </div>
            <div className='hyperfood--menuThankYou-2'>
              <span>Thank You!</span>
              <span>Thank You!</span>
            </div>
            <div className='hyperfood--menuThankYou-3'>
              <span>Thank You!</span>
              <span>Thank You!</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

HyperfoodMenu.contextType = HyperfoodContext;