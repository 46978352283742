import React from 'react';
import PropTypes from 'prop-types';
import HyperagePillsData from '../../data/HyperagePills.json';
import HyperageBreathePill from './HyperageBreathePill';

export default class HyperageBreathePills extends React.Component {

  pills(){
    return HyperagePillsData.find((group)=>{
      return group.group === this.props.theme
    }).pills;
  }
  renderPills() {
    return this.pills().map((pill)=>{
      return (
        <HyperageBreathePill 
          key={`p-${pill.key}`}
          currentPill={this.props.currentPill}
          onClickPill={()=>{this.props.onClickPill(pill)}}
          pill={pill} />
      );
    })
  }
  render() {
    return (
      <div 
        className='hyperage--breathe--pills'>
        {this.renderPills()}
      </div>
    );
  }
}

HyperageBreathePills.propTypes = {
  onClickPill: PropTypes.func,
  currentPill: PropTypes.object,
  theme: PropTypes.string
};
