import React from 'react';
import HomeNavItem from '../Home/HomeNavItem';

export default class GlobalNavSlideIn extends React.Component {

  render() {
    return (
      <div className='globalNav--slideIn'>
        <div className='globalNav--slideIn--items'>
          <HomeNavItem 
            key='home'
            className='home--navItemLinkActive'
            href='/'
            title='&#9756; Home'
            />
        </div>
      </div>
    );
  }
}