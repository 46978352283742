import React from 'react';

export default class HyperfoodFloaterDunkel1 extends React.Component {

  render() {
    return (
      <div className='hyperfood--floater--dunkel1'>
        감사합니다
      </div>
    );
  }
}
// translates to thank you