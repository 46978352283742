import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

export default class FontIndexThumb extends React.Component {
  active() {
    return window.location.pathname === `/font-index/${this.props.font.fontSlug}`
  }
  render() {
    const activeClass = this.active() ? 'fontIndex--thumbnail--active' : '';
    const path = this.active() ? '/font-index' : `/font-index/${this.props.font.fontSlug}`;
    return (
      <Link 
        key={`f-${this.props.font.fontSlug}`}
        to={path}
        className={`fontIndex--thumbnail fontIndex--thumbnail--${this.props.font.fontSlug} ${activeClass}`}>
        <div className='fontIndex--thumbnailFont'>
          {this.props.font.fontName}
        </div>
        <div className='fontIndex--thumbnailTextWrap'>
          <div className='fontIndex--thumbnailText'>
            {this.props.font.indexThumbText || 'Aa12'}
          </div>
        </div>
        <div className='fontIndex--thumbnailFoundry'>
          {this.props.font.foundryName}
        </div>
      </Link>
    );
  }
}

FontIndexThumb.propTypes = {
  font: PropTypes.object
};
