import React from 'react';
import HyperwebAdCongratsPwned from './HyperwebAdCongratsPwned';

export default class HyperwebAdCongrats extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      pwned: false
    }
  }
  handleClick() {
    this.setState({
      pwned: true
    });
  }
  render() {
    return (
      <div className='hyperwebAd--congrats'>
        {this.state.pwned ? 
          <HyperwebAdCongratsPwned />
        : null}
        <div className='hyperwebAd--congrats--urgent'>
          Urgent!!! Don’t miss out on this opportunity!!!
        </div>
        <div className='hyperwebAd--congrats--main'>
          <div className='hyperwebAd--congrats--title'>
            Congratulations
          </div>
          <div className='hyperwebAd--congrats--message'>
            You’ve been chosen to take part in a survey of experimental typefaces!!! Please enter your email and SSN in the textbox below for a chance to win $1,000,000!
          </div>
          <input 
            type='text'
            placeholder='Enter your email!'
            className='hyperwebAd--congrats--textbox' />
          <input 
            type='text'
            placeholder='Enter your SSN!'
            className='hyperwebAd--congrats--textbox' />
          <button 
            onClick={()=>{this.handleClick()}}
            className='hyperwebAd--congrats--submit'>
            Submit!
          </button>
        </div>
      </div>
    );
  }
}