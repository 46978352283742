import React from 'react';
import PropTypes from 'prop-types';
import FontDescriptions from '../../data/FontDescriptions.json';
import ReactMarkdown from 'react-markdown';

export default class FontIndexDetailMain extends React.Component {

  renderPhotos(){
    const format = this.props.font.foundryImgFormat || 'png';
    return (
      <img 
        alt={this.props.font.foundryName}
        src={`${PHOTO_DIR}${this.props.font.foundrySlug}.${format}`} />
    );
  }
  renderDescription(){
    const content = FontDescriptions.find((font)=>{
      return font.slug === this.props.font.fontSlug
    })
    return (
      <div className='fontIndex--detail--description'>
        <ReactMarkdown 
          source={content.description} />
      </div>
    );
  }
  render() {
    return (
      <div className={`fontIndex--detail--main fontIndex--detail--${this.props.font.fontSlug}`}>
        <div className={`fontIndex--detail--title`}>
          <span className={`fontIndex--detail--titleText font--${this.props.font.fontSlug}`}>{this.props.font.extendedFontName || this.props.font.fontName}</span>
        </div>
        <div className='fontIndex--detail--info'>
          <div className='fontIndex--detail--photos'>
            {this.renderPhotos()}
          </div>
          {this.renderDescription()}
        </div>
      </div>
    );
  }
}

FontIndexDetailMain.propTypes = {
  font: PropTypes.object
};

const PHOTO_DIR = '/images/font-index/foundries/';