import React from 'react';
import PropTypes from 'prop-types';
import Arr from '../../utils/Arr';

export default class SideScroller extends React.Component {
  renderText() {
    return Arr.mapTimes(3, (i)=>{
      if (this.props.text) {
        return (
          <span 
            key={`t-${i}`}
            className='sideScroller--text'>{this.props.text}</span>
        );
      } else {
        return (
          <span key={`t=${i}`}>
            {this.props.children}
          </span>
        );
      }
    });
  }
  render() {
    return (
      <div className={`sideSroller ${this.props.className}`}>
        <div className='sideScroller--content'>
          {this.renderText()}
        </div>
      </div>
    );
  }
}

SideScroller.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string
};

SideScroller.defaultProps = {
  className: ''
};

