import React from 'react';
import PropTypes from 'prop-types';
import FontIndexDetailHeader from './FontIndexDetailHeader';
import FontIndexDetailMain from './FontIndexDetailMain';
import FontIndexSpacer from './FontIndexSpacer';
import Scroll from '../../utils/Scroll';

export default class FontIndexDetail extends React.Component {
  componentDidUpdate(prevProps, prevState) {
    if (this.props.domLoaded && !prevProps.domLoaded) {
      setTimeout(()=>{
        Scroll.to(`.fontIndex--thumbnail--${this.props.font.fontSlug}`)
      }, 1000);
    }
  }
  componentDidMount() {
    if (document.readyState === "complete") {
      setTimeout(()=>{
        Scroll.to(`.fontIndex--thumbnail--${this.props.font.fontSlug}`);
      }, 500);
    } else {
      // window.addEventListener('load', ()=>{
      //   setTimeout(()=>{
      //     Scroll.to(`.fontIndex--thumbnail--${this.props.font.fontSlug}`)
      //   }, 2000);
      // })
    }
  }
  render() {
    const domLoadedClass = this.props.domLoaded ? 'domLoaded' : '';
    return (
      <div className={`fontIndex--detailRow ${domLoadedClass}`}>
        <FontIndexSpacer key={`spl`}/>
          <div className='fontIndex--detail'>
            <div className='fontIndex--detail--content'>
              <FontIndexDetailHeader
                font={this.props.font} />
              <FontIndexDetailMain
                font={this.props.font} />
            </div>
          </div>
        <FontIndexSpacer key={`spr`}/>
      </div>
    );
  }
}

FontIndexDetail.propTypes = {
  font: PropTypes.object
};
