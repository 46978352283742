import React from 'react';
import PropTypes from 'prop-types';
import HyperageBreatheControls from './HyperageBreatheControls';
import HyperageBreatheVisualizer from './HyperageBreatheVisualizer';
import Ease from '../../utils/Ease';
import IntersectionVisible    from 'react-intersection-visible';
import HyperageBreathePills from './HyperageBreathePills';
import HyperagePillsData from '../../data/HyperagePills.json';
import HyperageBreatheHeader from './HyperageBreatheHeader';


const ACTIVE = true;

export default class HyperageBreathe extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      frame: 0,
      breathQueueing: false,
      currentBreathePill: this.pills().length > 0 ? this.pills()[0] : null
    }
    this.breathTimer = null;
    this.holdBreathTimer = null;
  }
  componentDidMount() {
    this.beginBreathingSequence();
  }
  componentWillUnmount() {
    this.stopBreathing();
  }
  handleSliderInputChange(v) {
    this.stopBreathing();
    this.setFrameFromSliderVal(v);
  }
  handleSliderInputChangeComplete(){
    this.beginBreathingSequence();
  }
  handleClickBreathePill(pill) {
    this.setState({
      currentBreathePill: pill
    });
  }
  pills(){
    return HyperagePillsData.find((group)=>{
      return group.group === this.props.theme
    }).pills;
  }
  startBreathing() {
    if (!ACTIVE) {
      return
    }
    this.setState({
      breathQueueing: false
    });
    this.breathTimer = setInterval(()=>{
      this.incrementFrame();
    }, this.props.frameInterval);
  }
  stopBreathing() {
    clearInterval(this.breathTimer);
    clearTimeout(this.holdBreathTimer);
  }
  beginBreathingSequence(){
    clearTimeout(this.holdBreathTimer);
    this.holdBreathTimer = setTimeout(()=>{
      this.startBreathing()
    }, 1000);
    this.setState({
      breathQueueing: true
    });
  }
  incrementFrame(){
    window.requestAnimationFrame(()=>{
      this.setState({
        frame: this.state.frame + 1 
      });
    });
  }
  setFrameFromSliderVal(v) {
    const uneasedPct = (Ease.inverseEaseInOutQuad(v / 100) * this.props.framesPerCycle);
    let newFrameValue = this.cycleIndex() * this.props.framesPerCycle;

    if (this.direction() === 'down') {
      newFrameValue = newFrameValue + this.props.framesPerCycle - uneasedPct;
    } else {
      newFrameValue += uneasedPct;
    }
    this.setState({
      frame: newFrameValue
    });
  }
  cycleIndex() {
    return Math.floor(this.state.frame / this.props.framesPerCycle);
  }
  direction() {
    const isEvenCycle = this.cycleIndex() % 2 === 0;
    return isEvenCycle ? 'up' : 'down';
  }
  cyclePct() {
    if (this.direction() === 'down') {
      return (this.props.framesPerCycle - (this.state.frame % this.props.framesPerCycle)) / this.props.framesPerCycle ;  
    } else {
      return (this.state.frame % this.props.framesPerCycle) / this.props.framesPerCycle ;
    }
    
  }
  easedCyclePct() {
    return Ease.easeInOutQuad(this.cyclePct());
  }
  render() {
    const breathQueueingClass = this.state.breathQueueing ? 'hyperage--breathe--breathQueueing' : '';
    return (
      <IntersectionVisible
        onShow={()=>{this.beginBreathingSequence()}}
        onHide={()=>{this.stopBreathing()}}>
        <div 
          className={`hyperage--breathe ${breathQueueingClass} hyperage--breathe--theme--${this.props.theme}`}>

          <div className='hyperage--breathe--background' />

          <HyperageBreatheControls 
            axisValue={this.state.value}
            theme={this.props.theme}
            cyclePct={this.easedCyclePct()}
            onSliderInputMouseDown={(v)=>{this.handleSliderInputMouseDown(v)}}
            onSliderInputChangeComplete={(v)=>{this.handleSliderInputChangeComplete(v)}}
            onSliderInputChange={(v)=>{this.handleSliderInputChange(v)}} />

          <HyperageBreatheVisualizer
            cyclePct={this.easedCyclePct()}
            direction={this.direction()}
            theme={this.props.theme} />

          <HyperageBreatheHeader 
            theme={this.props.theme} />

          <HyperageBreathePills
            theme={this.props.theme}
            onClickPill={(p)=>{this.handleClickBreathePill(p)}}
            currentPill={this.state.currentBreathePill} />

        </div>
      </IntersectionVisible>
    );
  }
}

HyperageBreathe.propTypes = {
  theme: PropTypes.string
};

HyperageBreathe.defaultProps = {
  frameInterval: 500,
  framesPerCycle: 20
};
