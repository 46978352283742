import React from 'react';
import PropTypes from 'prop-types';
import Arr from '../../utils/Arr';

export default class HyperageHistoryControls extends React.Component {
  renderOptions() {
    return Arr.mapTimes(4, (i)=>{
      return (
        <div 
          key={`hhc-${i}`}
          onClick={()=>{this.props.onChangeTheme(i)}}
          className={`hyperage--history--controls--option hyperage--history--controls--option--${i}`} >
          <div className='hyperage--history--controls--option--droner' />
          <div className='hyperage--history--controls--option--droner' />
          <div className='hyperage--history--controls--option--droner' />
        </div>
      );
    });
  }
  render() {
    return (
      <div className='hyperage--history--controls'>
        <div className='hyperage--history--controls--sticky'>
          <div className='hyperage--history--controls--line' />
          <div className='hyperage--history--controls--options'>
            {this.renderOptions()}
          </div>
        </div>
      </div>
    );
  }
}

HyperageHistoryControls.propTypes = {
  theme: PropTypes.number,
  handleChangeTheme: PropTypes.func
};
