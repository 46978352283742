import React from 'react';

export default class HyperageHistoryThemePopup extends React.Component {
  theme() {
    return THEMES[this.props.theme]
  }
  render() {
    return (
      <div className={`hyperage--history--themePopup hyperage--history--themePopup--theme-${this.props.theme}`}>
        <span>
          {this.theme().title}
        </span>
      </div>
    );
  }
}

const THEMES = [
  {
    title: 'Mountain Pose'
  },
  {
    title: 'Cat Pose'
  },
  {
    title: 'Head Stand'
  },
  {
    title: 'Lotus'
  }
]