import React from 'react';
import HyperfoodCheckoutItems from './HyperfoodCheckoutItems';
import HyperfoodCheckoutButton from './HyperfoodCheckoutButton';

export default class HyperfoodCheckout extends React.Component {

  render() {
    return (
      <div className='hyperfood--checkout'>
        <div className='hyperfood--checkoutHeader'>
          <div className='hyperfood--checkoutHeader--english'>Confirm Order Here
          </div> 
        </div>

        <div className='hyperfood--checkoutMain'>
          <div className='hyperfood--checkoutDescription'>
            This screen helps ensure order accuracy and prompt service
          </div>

          <HyperfoodCheckoutItems />
          <HyperfoodCheckoutButton />
        </div>
      </div>
    );
  }
}