import React from 'react';
import Arr from '../../utils/Arr';
import Calc from '../../utils/Calc';

export default class HyperwebScreensaverFlyingLetters extends React.Component {

  renderLetters(){
    return LETTERS.map((letter)=>{
      return (
        <div 
          key={letter.id}
          style={{
            top: `-${letter.yStart}vh`,
            right: `-${letter.xStart}vw`,
            animationDelay: `-${letter.delay}s`
          }}
          className={`hyperweb--screensaver--flyingLetters--letter hyperweb--screensaver--flyingLetters--${letter.fontSlug}`}>
          <img 
            alt=''
            className='hyperweb--screensaver--flyingLetters--leftWing'
            src='/images/hyperweb/screensavers/wingflap.gif' />
          <span>{letter.letter}</span>
          <img 
            alt=''
            className='hyperweb--screensaver--flyingLetters--rightWing'
            src='/images/hyperweb/screensavers/wingflap.gif' />
        </div>
      );
    });
  }
  render() {
    return (
      <div className='hyperweb--screensaver hyperweb--screensaver--flyingLetters'>
        {this.renderLetters()}
      </div>
    );
  }
}

const RAW_LETTERS = [
  {letter: 'M', fontSlug: 'messer'},
  {letter: 'R', fontSlug: 'silverspoon'},
  {letter: 'g', fontSlug: 'nostraSett'}
];

const LETTERS = Arr.concatFuncTimes(20, (i)=>{
  return RAW_LETTERS.map((letter)=>{
      return {
        ...letter,
        xStart: Calc.randomIntBetween(3, 100),
        yStart: Calc.randomIntBetween(3, 100),
        delay: Math.random() * 19,
        id: `${i}-${letter.letter}-${letter.fontSlug}`
      }
    }
  );
});