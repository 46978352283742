import React from 'react';
import PropTypes from 'prop-types';
import FontIndexSpacer from './FontIndexSpacer';
import FontIndexThumb from './FontIndexThumb';
import FontIndexDetailRow from './FontIndexDetailRow';

export default class FontIndexRow extends React.Component {
  renderThumbnails() {
    return (
      this.props.fonts.map((font)=>{
        return (
          <FontIndexThumb 
            key={`fit-${font.fontSlug}`} 
            font={font} />
        );
      })
    );
  }
  renderMidSpacer(key) {
    if (this.props.isLast) {
      return (
        <FontIndexSpacer 
          key={`${key}-${this.props.index}`}
          isMid={true} />  
      );
    }
  }
  render() {
    const lastClass = this.props.isLast ? 'fontIndex--row--last' : '';
    return [
      <div 
        key={`fir-${this.props.index}`}
        className={`fontIndex--row ${lastClass}`}>
        <FontIndexSpacer key='spacer-l' />
        {this.renderMidSpacer('spm1')}
        {this.renderThumbnails()}
        {this.renderMidSpacer('spm2')}
        <FontIndexSpacer key='spacer-r' />
      </div>,
      <FontIndexDetailRow
        key={`fidr-${this.props.index}`}
        fonts={this.props.fonts}
        domLoaded={this.props.domLoaded} />
    ];
  }
}

FontIndexRow.propTypes = {
  fonts: PropTypes.array
};
