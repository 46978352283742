import React from 'react';
import {HyperfoodContext} from '../../contexts/HyperfoodContext';

export default class HyperfoodSuccessSubmitMessage extends React.Component {
  constructor(props){
    super(props);
    this.timer = null;
  }
  componentDidMount() {
    // this.addFloater(1000);
    this.addFloaters();
  }
  componentWillUnmount() {
    clearInterval(this.timer);
  }
  addFloaters(){
    this.timer = setInterval(()=>{
      this.context.onAddRandomFloater();
    }, 200);
  }
  addFloater(time){
    this.context.onAddRandomFloater();
    setTimeout(()=>{
      let nextTime = time;
      if (time > 500) {
        nextTime = time - 250;
      } else if (time > 100) {
        nextTime = time - 100;
      } else if (time > 10) {
        nextTime = time - 10;
      }
      this.addFloater(nextTime);
    }, time);
  }
  render() {
    return (
      <div className='hyperfood--successSubmitMessageSuccess'>
        <div className='hyperfood--successSubmitMessageSuccess--text'>
          Thanks for your order, <br/> have a super day!
        </div>
      </div>
    );
  }
}

HyperfoodSuccessSubmitMessage.contextType = HyperfoodContext;