import React from 'react';
import Calc from '../utils/Calc';
import Guid from 'guid';
import Arr from '../utils/Arr';

const HyperageFanPopupOptions = [
  {
    name: 'eye'
  },
  {
    name: 'globe'
  },
  {
    name: 'infinity'
  },
  {
    name: 'whirl'
  },
  {
    name: 'gradientEye'
  }
]

const HyperageFanRandomPopup = (data)=>{
  const index = Calc.randomIntBetween(0, HyperageFanPopupOptions.length - 1);

  return {
    id: Guid.create(),
    ...HyperageFanPopupOptions[index],
    ...data
  };
}

const HyperageFanRandomPopups = (num, data)=>{
  return Arr.mapTimes(num, (i)=>{
    return HyperageFanRandomPopup(data);
  }) 
}

const HyperageContext = React.createContext({});

export {
  HyperageContext,
  HyperageFanPopupOptions,
  HyperageFanRandomPopup,
  HyperageFanRandomPopups
}
