import React from 'react';

export default class HyperfoodFloaterQuality extends React.Component {

  render() {
    return (
      <div className='hyperfood--floater--quality'>
        <div className='hyperfood--floater--bg'>
        </div>
        <div className='hyperfood--floater--qualityText'>
         <div className='hyperfood--floater--qualityTextBot'>
           QUALITY
         </div>
         <div className='hyperfood--floater--qualityTextTop'>
           QUALITY
         </div>
        </div>
      </div>
    );
  }
}