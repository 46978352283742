import React from 'react';
import ReactMarkdown from 'react-markdown';
import HyperwebAds from './HyperwebAds';
import {HyperwebContext, HyperwebAsciiImages, HyperwebAsciiAdNames} from '../../contexts/HyperwebContext';
import Arr from '../../utils/Arr';

export default class HyperwebAscii extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      list: []
    }
  }
  componentDidMount() {
    this.setState({
      list: this.buildListWithAds()
    });
  }
  buildImages() {
    return HyperwebAsciiImages.map((image)=>{
      return (
        <div 
          key={image.name}
          className='hyperweb--asciiImage'>
          <ReactMarkdown 
            source={image.content} />
        </div>
      );
    })
  }
  buildAds() {
    return HyperwebAsciiAdNames.map((name)=>{
      return (
        <HyperwebAds
          key={name}
          name={name} />
      );
    });
  }
  buildListWithAds() {
    return Arr.shuffle(this.buildAds().concat(this.buildImages()));
  }
  render() {
    return (
      <div className='hyperweb--asciiImages'>
        <div className='hyperweb--columnHeader'>
          Cool Art
        </div>
        <div className='hyperweb--asciiImages--list'>
          {this.state.list}
        </div>
      </div>
    );
  }
}

HyperwebAscii.contextType = HyperwebContext;