import React from 'react';
import PropTypes from 'prop-types';
import Arr from '../../utils/Arr';

export default class TextOnCircle extends React.Component {
  renderText(){
    return Arr.mapWithIndex(this.props.text.split(''), (char, i)=> {
      return (
        <span 
          key={`c-${i}`}>
          {char}
        </span>

      );
    });
  }
  render() {
    return (
      <div className={`textOnCircle ${this.props.className}`}>
        {this.renderText()}
      </div>
    );
  }
}

TextOnCircle.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string
};
TextOnCircle.defaultProps = {
  text: '',
  className: ''
};
