function isEmpty(obj) {
  for(var key in obj) {
    if(obj.hasOwnProperty(key))
      return false;
  }
  return true;
}
function isEqual(obj1, obj2){
  if (obj1 === null || obj1 === undefined) {
    return (obj2 === null || obj2 === undefined);
  }
  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false;
  }
  let equal = true;
  Object.keys(obj1).sort().forEach((key)=>{
    if (typeof obj1[key] === 'object') {
      if (!isEqual(obj1[key], obj2[key])){
        equal = false;
      }
    } else {
      if (!Object.is(obj1[key], obj2[key])){
        equal = false;
      }
    }
  });
  return equal;
}
function removeEmptyAttributes(obj) {
  const o = JSON.parse(JSON.stringify(obj));

  Object.keys(o).forEach(key => {
    if (o[key] && typeof o[key] === 'object') {
      o[key] = removeEmptyAttributes(o[key]);
    } else if (o[key] === undefined || o[key] === null || o[key] === '') {
      delete o[key];
    } else {
      // o[key] = o[key];
    }
  });

  return o;
}

export default {
  isEmpty,
  isEqual,
  removeEmptyAttributes
}