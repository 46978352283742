import React from 'react';
import PropTypes from 'prop-types';

const HyperfoodDblOutlinedText = props => {

  return (
    <div 
      style={{
        fontFamily: props.font,
        ...props.style
      }}
      className={`hyperfood--doubleOutlinedText ${props.className}`}>
      <span>{props.text}</span>
      <span>{props.text}</span>
      <span>{props.text}</span>
    </div>
  );
};

HyperfoodDblOutlinedText.propTypes = {
  text: PropTypes.string,
  style: PropTypes.object
};

export default HyperfoodDblOutlinedText;