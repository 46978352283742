import React from 'react';
import {Route} from "react-router-dom";
import PropTypes from 'prop-types';
import FontIndexDetail from './FontIndexDetail';
import AnimateHeight from 'react-animate-height';

export default class FontIndexDetailRow extends React.Component {
  renderDetailViews() {
    return this.props.fonts.map((font)=>{
      return (
        <Route 
          key={`fidv-${font.fontSlug}`}
          path={`/font-index/${font.fontSlug}`}
          visible={true}
          render={(props) => {
            return (
              <FontIndexDetail 
                {...props} 
                domLoaded={this.props.domLoaded}
                font={font} />
              )
          }} />
      );
    })
  }
  rowActive() {
    const urlMatches = this.props.fonts.map((font)=>{
      return window.location.pathname === `/font-index/${font.fontSlug}`
    });
    return urlMatches.indexOf(true) >= 0;
  }
  render() {
    return (
      <AnimateHeight
        duration={SLIDE_IN_SPEED}
        height={this.rowActive() ? 'auto' : 0}>

        {this.renderDetailViews()}

      </AnimateHeight>
    );
  }
}

FontIndexDetailRow.propTypes = {
  fonts: PropTypes.array
};

const SLIDE_IN_SPEED = 500;