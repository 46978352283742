import React from 'react';
import {HyperfoodContext, HyperfoodTotalCost} from '../../contexts/HyperfoodContext';
import HyperfoodCheckoutItem from './HyperfoodCheckoutItem';
import HyperfoodDblOutlinedText from './HyperfoodDblOutlinedText';

export default class HyperfoodCheckoutItems extends React.Component {
  renderCartItems() {
    return this.context.cart.map((item, i)=>{
      return (
        <HyperfoodCheckoutItem 
          key={item.id}
          index={i}
          cartItem={item} />
      );
    })
  }
  render() {
    return (
      <div className='hyperfood--checkoutItems'>
        <div className='hyperfood--checkoutItemsList'>
          {this.renderCartItems()}
          {(this.context.cart.length === 0) ?
            <div className='hyperfood--checkoutEmpty'>
              Nothing in your cart!
            </div>
          : null }
        </div>
        <div className='hyperfood--checkoutTotal'>
          <div className='hyperfood--checkoutTotalArrow'>
            <img 
              src='/images/hyperfood/hyperfood-BigArrow.svg'
              alt='Arrow Right' />
          </div>
          <div className='hyperfood--checkoutTotalRightCol'>
            <div className='hyperfood--checkoutTotalLabel'>
              <HyperfoodDblOutlinedText
                font='Obviously VF'
                text='Total' />
            </div>
            <div className='hyperfood--checkoutTotalCost'>
              ${HyperfoodTotalCost(this.context.cart)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

HyperfoodCheckoutItems.contextType = HyperfoodContext;