import React from 'react';
import {HyperwebContext} from '../../contexts/HyperwebContext';
import Calc from '../../utils/Calc';

export default class HyperwebClippy extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      transitioning: false,
      font: 'afrikola'
    }
  }

  handleClick(){
    this.setState({
      transitioning: true
    });
    setTimeout(()=>{
      this.context.onClippyClick();
      this.setState({
        transitioning: false,
        font: FONTS[Calc.randomIntBetween(0, (FONTS.length - 1))]
      });
    }, 2000);
  }
  renderHelper(){
    if(this.context.showClippyHelper && !this.context.clippyClicked) {
      return (
        <div className='hyperweb--clippyBtn--helper'>
          Click Me!
        </div>
      );
    }
  }
  render() {
    const transitioningClass = this.state.transitioning ? 'hyperweb--clippyBtnTransitioning' : 'hyperweb--clippyBtnWaiting';
    return (
      <div 
        className={`hyperweb--clippyBtn ${transitioningClass} hyperweb--clippyBtnFont--${this.state.font}`}>
        <div 
          className={`hyperweb--clippyBtnW`}
          onClick={()=>{this.handleClick()}}>
          W 
        </div>
        <div className='hyperweb--clippyBtn--leftEye'></div>
        <div className='hyperweb--clippyBtn--rightEye'></div>
        {this.renderHelper()}
      </div>
    );
  }
}

HyperwebClippy.contextType = HyperwebContext;


const FONTS = [
  'afrikola',
  'henk-out',
  'blimey',
  'macabre',
  'forester',
  'banto'
]