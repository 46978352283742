import React from 'react';
import {Link} from 'react-router-dom';

export default class GlobalBackBtn extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      visible: false
    }
    this.scrollTimer = null;
    this.setVisibility = this.setVisibility.bind(this);
  }
  componentDidMount() {
    this.setVisibility();

    document.addEventListener('scroll', this.setVisibility);
    // window.onscroll = ()=>{
    //   this.setVisibility();
    // };
  }
  componentWillUnmount() {
    clearTimeout(this.scrollTimer);
    document.removeEventListener('scroll', this.setVisibility)
  }
  setVisibility() {
    clearTimeout(this.scrollTimer);

    this.scrollTimer = setTimeout(()=>{
      this.setState({
        visible: window.scrollY > 250
      });
    }, 500);
  }
  render() {
    if (this.state.visible) {
      return (
        <Link 
          to='/'
          className='globalBackBtn'>
          &#9756;
        </Link>
      );
    } else {
      return null;
    }
  }
}