import React from 'react';

export default class HyperfoodFloaterFries extends React.Component {

  render() {
    return (
      <div className='hyperfood--floater--fries'>
        Fries&nbsp;&<br/>
        Coke?
      </div>
    );
  }
}