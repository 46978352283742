import React from 'react';
import {HyperfoodContext} from '../../contexts/HyperfoodContext';
import HyperfoodCartItem from './HyperfoodCartItem';
import HyperfoodFloater from './HyperfoodFloater';

export default class HyperfoodFloaters extends React.Component {
  renderCartItems() {
    return this.context.cart.map((cartItem)=>{
      return (
        <HyperfoodCartItem
          key={cartItem.id}
          cartItem={cartItem}
          onItemRemove={()=>{this.context.onRemoveItem(cartItem)}} />
      )
    })
  }
  renderFloaters(){
    return this.context.floaters.map((floater)=>{
      return (
        <HyperfoodFloater
          key={floater.id}
          floater={floater}
          onItemRemove={()=>{this.context.onRemoveItem(floater, 'floaters')}} />
      );
    })
  }
  render() {
    return (
      <div>
        {this.renderFloaters()}
        {this.renderCartItems()}
      </div>
    );
  }
}

HyperfoodFloaters.contextType = HyperfoodContext;