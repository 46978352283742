import React from 'react';

export default class HyperfoodFloaterBuyNow extends React.Component {

  render() {
    return (
      <div className='hyperfood--floater--buyNow'>
        BUY NOW
      </div>
    );
  }
}