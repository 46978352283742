import React from 'react';
import PropTypes from 'prop-types';

export default class HyperageBreatheVisualizerBreathe extends React.Component {
  renderVariableStyles() {
    const vals = CHEEE_AXES.map((axis)=>{
      const range = (axis.maxVal - axis.minVal);
      const val = range * this.props.cyclePct + axis.minVal;
      // debugger
      return `'${axis.key}' ${val}`
    });
    return {
      fontVariationSettings: vals.join(', ')
    }
  }
  render() {
    return (
      <div className='hyperage--breathe--visualizer--breathe'>
        <div 
          style={this.renderVariableStyles()}
          className='hyperage--breathe--visualizer--breathe--glyph'>
          A
        </div>
      </div>
    );
  }
}

HyperageBreatheVisualizerBreathe.propTypes = {
  cyclePct: PropTypes.number
};

const CHEEE_AXES = [
  {
    key: 'grvt',
    minVal: 0,
    maxVal: 1000
  },
  {
    key: 'yest',
    minVal: 0,
    maxVal: 1000
  }
];