import React from 'react';
import PropTypes from 'prop-types';
import HyperageBreatheVisualizerBreathe from './HyperageBreatheVisualizerBreathe';
import HyperageBreatheVisualizerFan from './HyperageBreatheVisualizerFan';
import HyperageBreatheVisualizerStretch from './HyperageBreatheVisualizerStretch';

export default class HyperageBreatheVisualizer extends React.Component {
  
  yogaPoseFrame() {
    const numFrames = this.yogaPosesForTheme().length - 1;
    return Math.round(this.props.cyclePct * numFrames)
  }
  yogaPosesForTheme() {
    const poses = YOGA_POSES.find((pose)=>{
      return pose.theme === this.props.theme
    });
    if (poses) {
      return poses.poses;
    } else {
      return [];
    }
  }
  renderYogaPose(){
    return (
      <img 
        className={`hyperage--breathe--visualizerYogaImg hyperage--breathe--visualizerYogaImg--frame-${this.yogaPoseFrame()}`}
        src={`/images/hyperage/${this.yogaPosesForTheme()[this.yogaPoseFrame()]}`} 
        alt='' />
    )
  }
  renderGlyphVisualizer() {
    switch(this.props.theme) {
      case 'breathe':
        return (
          <HyperageBreatheVisualizerBreathe
            cyclePct={this.props.cyclePct} />
        );
      case 'fan':
        return (
          <HyperageBreatheVisualizerFan 
            cyclePct={this.props.cyclePct} />
        );
      case 'stretch':
      default:
        return (
          <HyperageBreatheVisualizerStretch 
            cyclePct={this.props.cyclePct} />
        );
    }
  }
  render() {
    return (
      <div className='hyperage--breathe--visualizer'>
        {this.yogaPosesForTheme().length > 0 ? 
          <div className='hyperage--breathe--visualizerYoga'>
            {this.renderYogaPose()}
          </div>
        : null }
        {this.renderGlyphVisualizer()}
      </div>
    );
  }
}

HyperageBreatheVisualizer.propTypes = {
  onSliderInputMouseUp: PropTypes.func,
  onSliderInputMouseDown: PropTypes.func,
  onSliderInputChange: PropTypes.func,
  cyclePct: PropTypes.number
};

const YOGA_POSES = [
  {
    theme: 'breathe',
    poses: [
      'hyperage-yoga-breathe-1.png',
      'hyperage-yoga-breathe-2.png',
      'hyperage-yoga-breathe-3.png',
      'hyperage-yoga-breathe-4.png'
    ]
  },
  {
    theme: 'fan',
    poses: [
      'hyperage-yoga-fan-1.png',
      'hyperage-yoga-fan-2.png'
    ]
  }
];