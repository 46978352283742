import React from 'react';

export default class HyperfoodHeader extends React.Component {
  renderBlocks() {
    let i = 0;
    let blocks = [];

    while(i < 10) {
      blocks.push(
        <div 
          key={`headerBlock-${i}`}
          className='hyperfood--headerBlock'>
          <span className='hyperfood--headerBlock--text'>WELCOME TO HYPERFOOD</span>
          <span className='hyperfood--headerBlock--arrows'>E&#x2193;F</span>
        </div>
      )
      i+=1;
    }
    return blocks;
  }
  render() {
    return (
      <nav className='hyperfood--header'>
        {this.renderBlocks()}
      </nav>
    );
  }
}