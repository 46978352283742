import React from 'react';

export default class HyperwebAdNeedHelp extends React.Component {

  render() {
    return (
      <a 
        href='https://www.futurefonts.xyz'
        target='_blank'
        rel="noopener noreferrer"
        className='hyperwebAd--needHelp'>
        <div className='hyperwebAd--needHelp--top'>
          We have the outlines and the prices that make you go whoa!
        </div>
        <div className='hyperwebAd--needHelp--main'>
          <span>NEED HELP WITH COOL FONTS?</span>
        </div>
        <div className='hyperwebAd--needHelp--url'>
          Call https://www.futurefonts.xyz
        </div>
      </a>
    );
  }
}