import React from 'react';
import Fonts from '../../data/FontData.json';
import {HyperwebContext} from '../../contexts/HyperwebContext';
import HyperwebCounter from './HyperwebCounter';

export default class HyperwebFontList extends React.Component {
  handleClick(font) {
    this.context.onAddPopup('textEditorPopups', font);
  }
  renderFonts() {
    return Fonts.map((font)=>{
      return (
        <div 
          key={font.fontSlug}
          className={`hyperweb--fontList--font font--${font.fontSlug}`}>
          <span
            onClick={()=>{this.handleClick(font)}}>
            {font.safeFontName || font.fontName}
          </span>
        </div>
      );
    })
  }
  render() {
    return (
      <div className='hyperweb--fontList'>
        <div className='hyperweb--columnHeader'>
          Choose a Font
        </div>
        <div className='hyperweb--fontList--fonts'>
          {this.renderFonts()}
        </div>
        <HyperwebCounter />
      </div>
    );
  }
}

HyperwebFontList.contextType = HyperwebContext;