import React from 'react';
import PropTypes from 'prop-types';

export default class HyperagePalmOption extends React.Component {
  renderNumber() {
    const outlinedClass = this.props.option.numberOutlined ? 'hyperage--palm--option--number--numberOutlined' : '';
    return (
      <div className={`hyperage--palm--option--number--number ${outlinedClass}`}>
        {this.props.option.number}
      </div>
    );
  }
  renderOutsideBox() {
    return (
      <div className='hyperage--palm--option--outsideBox--box' />
    );
  }
  renderBox() {
    return (
      <div className='hyperage--palm--option--box--box' />
    );
  }
  renderExtras() {
    switch (this.props.option.type) {
      case 'number':
        return this.renderNumber();
      case 'outsideBox':
        return this.renderOutsideBox();
      default:
        return null;
    }
  }
  render() {
    return (
      <div 
        onClick={()=>{this.props.onOptionSelect(this.props.option)}}
        className={`hyperage--palm--option font--${this.props.option.font} hyperage--palm--option--${this.props.option.type} hyperage--palm--option--${this.props.option.slug}`}>
        {this.renderExtras()}
        <span className='hyperage--palm--option--label'>{this.props.option.label}</span>   
      </div>
    );
  }
}

HyperagePalmOption.propTypes = {
  option: PropTypes.object,
  onOptionSelect: PropTypes.func
};
