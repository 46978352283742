import React from 'react';
import PropTypes from 'prop-types';
import Calc from '../../utils/Calc';

export default class HyperageFanGradientEye extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      theme: this.props.theme
    }
  }
  render() {
    return (
      <div className={`hyperage--fan--popup--gradientEye hyperage--fan--popup--gradientEye--theme${this.state.theme}`}>
        <div className='hyperage--fan--popup--gradientEye1' />
        <div className='hyperage--fan--popup--gradientEye2' />
      </div>
    );
  }
}
HyperageFanGradientEye.propTypes = {
  theme: PropTypes.number
};

HyperageFanGradientEye.defaultProps = {
  theme: Calc.randomIntBetween(1,3)
};
