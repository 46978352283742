import React from 'react';
import PropTypes from 'prop-types';
import Calc from '../../utils/Calc';

export default class HyperageBreathePill extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      x: 0,
      y: 0,
      rotate: 0,
      transitioning: false
    }
    this.transitionTimer = null;
  }
  handleReposition(location, force) {
    if (!this.state.transitioning || force) {
      clearTimeout(this.transitionTimer);

      this.setState({
        ...location,
        transitioning: true
      });

      this.transitionTimer = setTimeout(()=>{
        this.setState({
          transitioning: false
        });
      }, 1000);
    }
  }
  handleHover() {
    this.handleReposition(
      {
        x: Calc.randomIntBetween(-80,80),
        y: Calc.randomIntBetween(-80,80),
        rotate: Calc.randomIntBetween(-30,30)
      }
    )
  }
  handleClick() {
    this.handleReposition({
      rotate: 690
    }, true)
    this.props.onClickPill(this.props.pill);
  }
  style(){
    return {
      transform: `translate(${this.state.x}%, ${this.state.y}%) rotate(${this.state.rotate}deg)`
    }
  }
  render() {
    const activeClass = (this.props.currentPill.key === this.props.pill.key) ? 'hyperage--breathe--pill--active' : ''; 
    return (
      <div 
        key={`p-${this.props.pill.key}`}
        onClick={()=>{this.handleClick()}}
        onMouseOver={()=>{this.handleHover()}}
        style={this.style()}
        className={`hyperage--breathe--pill hyperage--breathe--pill--${this.props.pill.key} ${activeClass}`}>
        {this.props.pill.label}   
      </div>
    );
  }
}

HyperageBreathePill.propTypes = {
  pill: PropTypes.object,
  currentPill: PropTypes.object,
  onClickPill: PropTypes.func
};
