import React from 'react';
import { ReactComponent as HexagonSvg } from '../../images/shapes/hexagon.svg';

export default class Hexagon extends React.Component {

  render() {
    return (
      <div className='hexagon'>
        <HexagonSvg />
      </div>
    );
  }
}