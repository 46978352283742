import React from 'react';
import {Link} from 'react-router-dom';

export default class HyperwebAdHyperfood extends React.Component {

  render() {
    return (
      <Link
        to='/hyperfood'
        className='hyperwebAd--hyperfood'>
        <div className='hyperwebAd--hyperfood--row1'>
          <div className='hyperwebAd--hyperfood--logo'>
            <img 
              className='hyperwebAd--hyperfood--logoImg' 
              src='/images/hyperfood/hyperfood-Logo2.svg'
              alt='hyperfood'
              data-font-family='Funkford' />
          </div>
          <div className='hyperwebAd--hyperfood--order-now'>
            <span>Order Now...</span>
            <span>Order Now...</span>
          </div>
        </div>
        <div className='hyperwebAd--hyperfood--row2'>
          <span>beefy. saucy. bliss.</span>
        </div>
        <div className='hyperwebAd--hyperfood--fish'>
          <img 
            className='hyperwebAd--hyperfood--fishImg'
            src='/images/hyperfood/hyperfood-Fish-thumb.png'
            alt='hyperfood'
            data-ignore-inspector={true} />
        </div>
      </Link>
    );
  }
}