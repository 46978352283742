import React from 'react';

export default class HyperfoodFloater2018 extends React.Component {

  render() {
    return (
      <div className='hyperfood--floater--2018'>
        Since<br />
        2018
      </div>
    );
  }
}