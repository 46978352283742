import Ahoy from "ahoy.js";

const env = process.env.NODE_ENV || 'development';

const configuration = {
  cookies: false,
  urlPrefix: "https://www.futurefonts.xyz",
  trackVisits: false,
  useBeacon: false
}

function event(name, opts={}) {
  if (env !== 'development') {
    Ahoy.configure(configuration);
    Ahoy.track(name, {
      path: window.location.pathname,
      ...opts
    })
  }
}

function initialHit(opts={}) {
  setTimeout(()=>{
    event('HyperTextVisit');
  }, 3000);
  
}
function pageLoad(opts={}) {
  setTimeout(()=>{
    event('HyperTextPageLoad');
  }, 1000);
}

export default {
  initialHit,
  pageLoad
}