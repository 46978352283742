import React from 'react';
import PropTypes from 'prop-types';
import HyperwebScreensaverFlyingLetters from './HyperwebScreensaverFlyingLetters';
import HyperwebScreensaverDvd from './HyperwebScreensaverDvd';
import HyperwebScreensaver3dText from './HyperwebScreensaver3dText';

export default class HyperwebScreensaver extends React.Component {

  renderContent() {
    switch (this.props.id) {
      case 'dvd':
        return <HyperwebScreensaverDvd />
      case '3dText':
        return <HyperwebScreensaver3dText />
      case 'flyingLetters':
      default: 
        return <HyperwebScreensaverFlyingLetters />
    }
  }
  render() {
    return this.renderContent()
  }
}

HyperwebScreensaver.propTypes = {
  id: PropTypes.string
};
