import React from 'react';
import PropTypes from 'prop-types';
import Calc from '../../utils/Calc';
import Dom from '../../utils/Dom';
import { ReactComponent as InfinitySvg } from '../../images/hyperage/hyperage-fan-infinity.svg';
import { ReactComponent as EyeSvg } from '../../images/hyperage/hyperage-fan-eye.svg';
import { ReactComponent as GlobeSvg } from '../../images/hyperage/hyperage-fan-globe.svg';
import { ReactComponent as StarsSvg } from '../../images/hyperage/hyperage-fan-stars.svg';
import { ReactComponent as WhirlSvg } from '../../images/hyperage/hyperage-fan-whirl.svg';
import HyperageFanGradientEye from './HyperageFanGradientEye';


export default class HyperageFanPopup extends React.Component {
  constructor(props){
    super(props);
    this.state = (this.props.popup.skipAnimate ? this.randomLocation() : this.initialLocation());
    
  }
  componentDidMount() {
    if (!this.props.popup.skipAnimate) {
      setTimeout(()=>{
        this.moveToRandomSpot();
      }, 100); 
    }
  }
  initialLocation() {
    const target = Dom.findFirst('.hyperage--breathe--visualizer--fan--glyphImg').getBoundingClientRect();
    const page = Dom.findFirst('body').getBoundingClientRect();
    const x = (target.left + window.scrollX + (target.width * 0.38)) / page.width * 100;
    const y = (target.top + window.scrollY + (target.height * 0.46)) / page.height * 100;

    // debugger
    return {
      x: x,
      y: y,
      xScale: .5,
      yScale: .5,
      rotate: 0,
      opacity: 1
    }
  }
  randomLocation() {
    return {
      x: Calc.randomIntBetween(-5,95),
      y: Calc.randomIntBetween(0,100),
      xScale: Calc.randomIntBetween(50,300) / 100,
      yScale: Calc.randomIntBetween(50,300) / 100,
      rotate: Calc.randomIntBetween(0,360),
      opacity: 1
    }
  }
  moveToRandomSpot() {
    this.setState(this.randomLocation());
  }
  style() {
    return {
      top: `${this.state.y}%`,
      left: `${this.state.x}%`,
      transform: `translate3d(0, 0, 0) scale(${this.state.xScale}, ${this.state.yScale}) rotate(${this.state.rotate}deg)`,
      opacity: this.state.opacity
    }
  }
  renderSvg() {
    switch(this.props.popup.name) {
      case 'gradientEye':
        return <HyperageFanGradientEye />
      case 'eye':
        return <EyeSvg />
      case 'globe': 
        return <GlobeSvg />
      case 'stars':
        return <StarsSvg />
      case 'whirl':
        return <WhirlSvg />
      case 'infinity':
      default:
        return <InfinitySvg />
    }
  }
  render() {
    const animateClass = this.props.popup.skipAnimate ? 'hyperage--fan--popup--skipAnimate' : 'hyperage--fan--popup--animate';
    return (
      <div 
        style={this.style()}
        className={`hyperage--fan--popup hyperage--fan--popup--${this.props.popup.name} ${animateClass}`}>
        {this.renderSvg()}
      </div>
    );
  }
}

HyperageFanPopup.propTypes = {
  popup: PropTypes.object
};
