import React from 'react';
import Calc from '../utils/Calc';
import Arr from '../utils/Arr';
import Str from '../utils/Str';
import {HyperwebAsciiImages as HyperwebAsciiImagesData } from '../data/HyperwebAsciiImages';
import HyperwebReviews from '../data/HyperwebReviews.json';
import FontData from '../data/FontData.json';
import HyperwebImagePopups from '../data/HyperwebImagePopups.json';


const HyperwebContextTextEditorPopups = FontData;
const HyperwebContextReviews = Arr.shuffle(HyperwebReviews);

const HyperwebContextImagePopups = HyperwebImagePopups.map((img)=>{
  return {
    ...img,
    path: `/images/hyperweb/imagePopups/${img.fileName}`,
    key: Str.slugify(img.fileName.replace('.gif',''))
  }
})

const HyperwebContextRandomImagePopups = (num)=>{
  const randomPopups = Arr.shuffle(HyperwebContextImagePopups);
  return Arr.mapTimes(num, (i)=>{
    return randomPopups[i];
  });
}

// const HyperwebContextReviewsSlugs = HyperwebReviews.map((review)=>{
//   return review.fontSlug
// })
// const HyperwebContextTextEditorPopupsWithReviews = HyperwebContextTextEditorPopups.filter((popup)=>{
//   return HyperwebContextReviewsSlugs.indexOf(popup.fontSlug) >= 0;

// })

const HyperwebContextRandomPopup = (popupType)=>{
  const popupGroup = (popupType === 'textEditorPopups' ? HyperwebContextTextEditorPopups : HyperwebContextImagePopups);
  const randIndex = Calc.randomIntBetween(0, popupGroup.length - 1);
  return popupGroup[randIndex];
}

const HyperwebContextFindTextEditorPopup = (fontSlug)=>{
  return HyperwebContextTextEditorPopups.find((popup)=>{
    return popup.fontSlug === fontSlug;
  });
}

const HyperwebContextTextEditorContent = [
  'Info Superhighway',
  'Napster 4evr',
  'Limewire ruined my lyfe',
  'LMAO',
  'ttyl loser',
  '<3 CyBr GrL <3',
  'Online Now',
  'idk google it',
  'Ask Mr. Jeeves',
  'ROFLcopter',
  'Hampster Dance',
  'All Your Base Are Belong to Us',
  'Flame War',
  'Badger Badger Badger',
  'pwned',
  'i think u got hacked :-( ',
  '143 bby',
  'idk my bff Jill',
  'rawr lol',
  'mouse pad is bae',
  'L337 Sp3Ak t1l i D13',
  'Hot chips and lie',
  'Doritos r gud',
  'Phreakazoid'
];

const HyperwebContextRandomTextEditorString = ()=>{
  return Arr.shuffle(HyperwebContextTextEditorContent)[0];
}

const HyperwebContextTextEditorThemes = [
  'yellow-red-outline',
  'blue-pink-shadow',
  'red-blue-shadow',
  'green-black-slight-shadow',
  'blue-green-shadow',
  'pink-yellow-shadow',
  'blue-outline-green-shadow',
  'blue-clear-shadow',
  'yellow-slight-green-shadow',
  'cyan-blue-shadow',
  'orange-black-shadow',
  'forest-green-shadow',
  'black-green-shadow',
  'yellow-black-outline',
  'yellow-black-outline',
  'blue-outline',
  'green-blue-outline',
  'blue-outline-blue-shadow',
  'white-black-outline-black-shadow',
  'green-black-shadow'
];

const HyperwebContextRandomTextEditorTheme = ()=>{
  return Arr.shuffle(HyperwebContextTextEditorThemes)[0];
}

const HyperwebAsciiImages = Arr.shuffle(HyperwebAsciiImagesData);

const HyperwebContextFontTextEditorActive = (context, fontSlug)=>{
  const activeFontSlugs = context.textEditorPopups.map((popup)=>{
    return popup.item.fontSlug;
  });
  return activeFontSlugs.indexOf(fontSlug) >= 0;
}

const HyperwebContext = React.createContext({});

const HyperwebReviewsAdNames = ['coolFonts', 'cookies', 'congrats'];
const HyperwebAsciiAdNames = ['needHelp', 'hyperfood', 'hyperage'];

export {
  HyperwebContext,
  HyperwebContextReviews,
  HyperwebReviewsAdNames,
  HyperwebContextTextEditorPopups,
  HyperwebContextImagePopups,
  HyperwebContextRandomPopup,
  HyperwebContextRandomImagePopups,
  HyperwebContextFindTextEditorPopup,
  HyperwebAsciiImages,
  HyperwebAsciiAdNames,
  HyperwebContextRandomTextEditorString,
  HyperwebContextRandomTextEditorTheme,
  HyperwebContextFontTextEditorActive
}
