import React from 'react';
import PropTypes from 'prop-types';
import HyperfoodDblOutlinedText from './HyperfoodDblOutlinedText';
import {HyperfoodContext, HyperfoodFindFontStyleForItem} from '../../contexts/HyperfoodContext';

export default class HyperfoodMenuItem extends React.Component {
  handleClick() {
    this.props.onItemClick(this.props.item);
  }
  
  render() {
    const extraClass = this.props.item.extraClass || '';
    return (
      <div 
        onClick={()=>{this.handleClick()}}
        className={`hyperfood--menuItem hyperfood--menuItem--${this.props.size} hyperfood--menuItem--${this.props.item.name} hyperfood--menuItem--${this.props.item.orientation} ${extraClass}`}>
        <div className='hyperfood--menuItemSpacer'></div>
        <div className='hyperfood--menuItemOval'>
        </div>
        
        <img 
          src='/images/hyperfood/hyperfood-Burst.svg'
          className='hyperfood--menuItemBurst'
          alt='' />
        <div className='hyperfood--menuItemImgWrapper'>
          <img 
            src={`/images/hyperfood/${this.props.item.filename}`}
            className='hyperfood--menuItemImg'
            alt={this.props.item.name} />
        </div>
        <div className='hyperfood--menuCost'>
          <span>${this.props.item.cost.toString().replace(/^0+/, '')}</span>
        </div>
        {this.props.menuNumber ? 
          <div className='hyperfood--menuItemNumber'>
            <div className='hyperfood--menuItemNumber--circle'></div>
            <div className='hyperfood--menuItemNumber--number'>
              {this.props.menuNumber}
            </div>
          </div>
        : null}
        <div className='hyperfood--menuItemName'>
          <HyperfoodDblOutlinedText
            className={`hyperfood--menuItemName--english hyperfood--menuItemName--${this.props.item.fontFamilyClass}`}
            style={HyperfoodFindFontStyleForItem(this.props.item)}
            text={this.props.item.label} />
        </div>
      </div>
    );
  }
}

HyperfoodMenuItem.propTypes = {
  foodType: PropTypes.string,
  menuNumber: PropTypes.number,
  size: PropTypes.string
};

HyperfoodMenuItem.defaultProps = {
  size: 'small'
};

HyperfoodMenuItem.contextType = HyperfoodContext;
