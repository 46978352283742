import React from 'react';
// import PropTypes from 'prop-types';
import Arr from '../../utils/Arr';

export default class HyperageHeader extends React.Component {
  renderRow1(){
    return Arr.mapTimes(8, (i)=>{
      return (
        <div 
          key={`hp-row1-${i}`}
          className='hyperage--headerRow1--block'>
          <span>
            Welcome To Hyperage
          </span>
          <span>
            please stay a while and relax
          </span>
        </div>
      );
    })
  }
  renderRow2(){
    return Arr.mapTimes(8, (i)=>{
      return (
        <div 
          key={`hp-row2-${i}`}
          className='hyperage--headerRow2--block'>
          <span>
            BE HERE NOW
          </span>
          <span>
            & CALL 1–800–FUTURE-FONTS
          </span>
        </div>
      );
    })
  }
  render() {
    return (
      <div className='hyperage--header'>
        <div className='hyperage--headerRow1'>
          {this.renderRow1()}
        </div>
      </div>
    );
  }
}