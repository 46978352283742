import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from './Home/Home';
import Hyperfood from './Hyperfood/Hyperfood';
import Hyperage from './Hyperage/Hyperage';
import Hyperweb from './Hyperweb/Hyperweb';
import FontInspector from './Global/FontInspector';
import BrowserWarning from './Global/BrowserWarning';
import Preloader from './Global/Preloader';
import Track from '../utils/Track';

class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      preloaded: false,
      timeReady: false,
      transitioning: false,
      ready: false,
      fontInspectorActive: false
    }
  }
  componentDidMount() {
    setTimeout(()=>{
      this.setState({
        timeReady: true,
        transitioning: this.state.preloaded
      });
    }, 5000);

    // in case it takes too long
    setTimeout(()=>{
      this.setState({
        preloaded: true,
        transitioning: true
      });
    }, 12000);
    
    Track.initialHit();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.transitioning && !prevState.transitioning) {
      setTimeout(()=>{
        this.setState({
          ready: true
        });
      }, 500);
    }
  }
  handlePreloaded() {
    this.setState({
      preloaded: true,
      transitioning: this.state.preloaded
    });
  }
  handleFontInspectorChange(value){
    this.setState({
      fontInspectorActive: value
    });
  }
  renderLoaded() {
    const fontInspectorClass = this.state.fontInspectorActive ? 'fontInspectorActive' : '';
    return (
      <BrowserWarning 
        className={fontInspectorClass}>
        <Router>
          <Switch>
            <Route 
              key='hyperfood'
              path="/hyperfood" 
              component={Hyperfood} />
            <Route 
              key='hyperweb'
              path="/hyperweb" 
              component={Hyperweb} />
            <Route 
              key='hyperage'
              path="/hyperage" 
              component={Hyperage} />
            <Route 
              key='home'
              path="/" 
              component={(p)=>{
                return (
                  <Home 
                    domLoaded={this.state.ready}
                    {...p} />
                )}
              }
               />
          </Switch>
          <FontInspector
            onFontInspectorChange={(v)=>{this.handleFontInspectorChange(v)}} />
        </Router>
      </BrowserWarning>
    );
  }
  render() {
    const transitioningClass = (this.state.transitioning && !this.state.ready) ? 'browser--transitioning' : '';
    return (
      <div className={transitioningClass}>
        {(this.state.ready || this.state.transitioning) ? 
          this.renderLoaded()
        : null}
        {!this.state.ready ? 
          <Preloader
            transitioning={this.state.transitioning}
            onPreloaded={()=>{this.handlePreloaded()}} />
        : null}
      </div>
    );
  }
}

export default App;
