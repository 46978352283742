import React from 'react';
import PropTypes from 'prop-types';

export default class HyperagePalmSelectedOption extends React.Component {

  render() {
    if (this.props.selectedOption) {
      return (
        <div className='hyperage--palm--selectedOption'>
          <div className={`hyperage--palm--selectedOption--title font--${this.props.selectedOption.font}`}>
            {this.props.selectedOption.label}
          </div>
          <div className='hyperage--palm--selectedOption--description'>
            {this.props.selectedOption.description}
          </div>
        </div>
      );
    } else {
      return null;
    };
  }
}

HyperagePalmSelectedOption.propTypes = {
  selectedOption: PropTypes.object
};
