import React from 'react';
import {HyperfoodContext} from '../../contexts/HyperfoodContext';
import HyperfoodEmptySubmitMessage from './HyperfoodEmptySubmitMessage';
import HyperfoodSuccessSubmitMessage from './HyperfoodSuccessSubmitMessage';

export default class HyperfoodSubmitMessage extends React.Component {

  render() {
    if (this.context.submitted && this.context.cart.length === 0) {
      return <HyperfoodEmptySubmitMessage />
    } else if (this.context.submitted) {
      return <HyperfoodSuccessSubmitMessage />;
    } else {
      return null;
    }
  }
}
HyperfoodSubmitMessage.contextType = HyperfoodContext;

