import React from 'react';
// import PropTypes from 'prop-types';

export default class HyperageBigBigText extends React.Component {

  render() {
    return (
      <div className='hyperage--bigBigText'>
        <div className='hyperage--bigBigText--container'>
          <div className='hyperage--bigBigText--text hyperage--bigBigText--line1'>
            TATRA STHITAU YATNO 'BHYASAH TATRA STHITAU YATNO 'BHYASAH TATRA STHITAU YATNO 'BHYASAH TATRA STHITAU YATNO 'BHYASAH TATRA STHITAU YATNO 'BHYASAH TATRA STHITAU YATNO 'BHYASAH
          </div>
          <div className='hyperage--bigBigText--text hyperage--bigBigText--line2'>
            YOGAS CITTA-VRTTI YOGAS CITTA-VRTTI YOGAS CITTA-VRTTI YOGAS CITTA-VRTTI YOGAS CITTA-VRTTI YOGAS CITTA-VRTTI YOGAS CITTA-VRTTI
          </div>
          <div className='hyperage--bigBigText--text hyperage--bigBigText--line3'>
            STHIRA-SUKHAM ASANAM STHIRA-SUKHAM ASANAM STHIRA-SUKHAM ASANAM STHIRA-SUKHAM ASANAM STHIRA-SUKHAM ASANAM STHIRA-SUKHAM ASANAM
          </div>
        </div>
      </div>
    );
  }
}