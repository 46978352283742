import React from 'react';
import HyperwarpTitle from './HyperwarpTitle';
import HyperwarpStars from './HyperwarpStars';

export default class Hyperwarp extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      ready: false
    }
  }
  componentDidMount() {
    setTimeout(()=>{
      this.setState({
        ready: true
      });
    }, 500);
  }
  render() {
    const readyClass = (this.state.ready && !this.props.transitioning ? 'hyperwarp--ready' : '');
    return (
      <div className={`hyperwarp ${readyClass}`}>
        <HyperwarpTitle />
        <HyperwarpStars />
      </div>
    );
  }
}
