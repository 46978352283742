import React from 'react';
import PropTypes from 'prop-types';
import {HyperwebContext, HyperwebContextFindTextEditorPopup, HyperwebContextFontTextEditorActive} from '../../contexts/HyperwebContext';
import Arr from '../../utils/Arr';
import { ReactComponent as StarSvg } from '../../images/shapes/star.svg';

export default class HyperwebReview extends React.Component {
  handleClick(){
    this.context.onAddPopup('textEditorPopups', this.textEditorFont());
  }
  textEditorFont() {
    return HyperwebContextFindTextEditorPopup(this.props.review.fontSlug);
  }
  renderRating() {
    return Arr.mapTimes(parseInt(this.props.review.rating), (i)=>{
      return (
        <StarSvg
          key={`str-${i}`}
          className='hyperweb--reviews--reviewStar' />
      );
    })
  }
  renderSectionValue(section) {
    switch(section.name) {
      case 'fontSlug':
        return this.textEditorFont().fontName;
      case 'rating':
        return this.renderRating();
      default:
        return this.props.review[section.name];

    }
  }
  renderShortSections(){
    return SHORT_SECTIONS.map((section)=>{
      const clickParam = (section.clickable ? {onClick: ()=>{this.handleClick()}} : {})
      return (
        <div 
          key={`s-${section.name}`}
          className={`hyperweb--reviews--reviewItem hyperweb--reviews--reviewItem--${section.name}`}>
          <div 
            className='hyperweb--reviews--reviewItemRow'
            {...clickParam}>
            
            <span className='hyperweb--reviews--reviewItemLabel'>{section.label}:</span>{' '}

            <span className='hyperweb--reviews--reviewItemValue'>
              {this.renderSectionValue(section)}
            </span>
          </div>
        </div>
      );
    })
  }
  render() {
    const activeClass = HyperwebContextFontTextEditorActive(this.context, this.props.review.fontSlug) ? 'hyperweb--reviews--reviewItem--activePopup' : '';
    return (
      <div className={`hyperweb--reviews--review hyperweb--reviews--review--${this.props.review.fontSlug} ${activeClass}`}>
        <div className='hyperweb--reviews--reviewBreakdown'>
          {this.renderShortSections()}
        </div>
        <div className='hyperweb--reviews--reviewMessage'>
          {this.props.review.message}
        </div>
        <div className='hyperweb--reviews--reviewSpacer'>
          --
        </div>
      </div>
    );
  }
}

HyperwebReview.propTypes = {
  review: PropTypes.object.isRequired
};
HyperwebReview.contextType = HyperwebContext;

const SHORT_SECTIONS = [
  {name: 'fontSlug', label: 'Font', clickable: true},
  {name: 'rating', label: 'Rating'},
  {name: 'reviewerName', label: 'Reviewed By'},
  {name: 'favoriteGlyph', label: 'Favorite Glyph'},
  {name: 'bestPairedWith', label: 'Best Paired With'},
  {name: 'remindsMeOf', label: 'Reminds Me Of'}
]
