import React from 'react';
import TextOnCircle from '../Misc/TextOnCircle';

export default class HyperageIntro extends React.Component {

  render() {
    return (
      <div className='hyperage--intro'>
        <div className='hyperage--intro--logo'>
          HyperAge
        </div>
        <div className='hyperage--intro--circles'>
          <div className='hyperage--intro--circles--content'>
            <div className='hyperage--hands'>
              <img 
                className='hyperage--intro--hand-1'
                src='/images/hyperage/hyperage-hand-1.png' alt='' />
              <img 
                className='hyperage--intro--hand-2'
                src='/images/hyperage/hyperage-hand-2.png' alt='' />
            </div>
            <div className='hyperage--intro--circles--text'>
              <TextOnCircle
                key='1800'
                className='hyperage--intro--1800'
                text='1-80H-YPE-RAGE' />
              <TextOnCircle
                key='callnow'
                className='hyperage--intro--callNow'
                text='CALL NOW TO BEGIN YOUR PATH CALL NOW TO BEGIN YOUR PATH ' />
            </div>
            <div className='hyperage--intro--circles--centerText'>
              Our history has many places of obscurity and uncertainty due to its oral transmission of sacred texts and the secretive nature of its teachings. The early writings regarding our beliefs were transcribed on fragile palm leaves that were easily damaged, destroyed or lost.
            </div>
          </div>
        </div>
      </div>
    );
  }
}