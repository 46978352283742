import React from 'react';
import FontData from '../../data/FontData.json';
import Hyperwarp from '../Hyperwarp/Hyperwarp';

export default class Preloader extends React.Component {
  constructor(props){
    super(props);
    this.handlePageLoad = this.handlePageLoad.bind(this);
  }
  componentDidMount() {
    window.addEventListener("load", this.handlePageLoad);
  }
  handlePageLoad() {
    this.props.onPreloaded();
  }
  renderFonts(){
    return FontData.map((font)=>{
      return (
        <span 
          key={`f-${font.fontSlug}`}
          className={`font--${font.fontSlug}`}>
          Aa
        </span>
      )
    })
  }
  render() {
    return (
      <div className='preloader'>
        <Hyperwarp transitioning={this.props.transitioning} />
        <div className='preloader--fonts'>
          {this.renderFonts()}
        </div>
      </div>
    );
  }
}