import React from 'react';
import HyperageHistoryContent from './HyperageHistoryContent';
import HyperageHistoryControls from './HyperageHistoryControls';
import HyperageHistoryThemePopup from './HyperageHistoryThemePopup';

export default class HyperageHistory extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      theme: 0,
      nextTheme: 0,
      themePopupVisible: false
    }
    this.hidePopupTimer = null;
    this.changeFontTimer = null;
  }
  handleChangeTheme(theme) {
    this.setState({
      nextTheme: theme,
      themePopupVisible: true
    });
    this.queueCloseThemePopup(theme);
  }
  queueCloseThemePopup(theme){
    clearTimeout(this.changeFontTimer);
    clearTimeout(this.hidePopupTimer);
    this.changeFontTimer = setTimeout(()=>{
      this.setState({
        theme: theme
      });
    }, 2000);
    this.hidePopupTimer = setTimeout(()=>{
      this.setState({
        themePopupVisible: false
      });
    }, 4000);
  }
  render() {
    return (
      <div className={`hyperage--history hyperage--history--theme-${this.state.theme}`}>
        <HyperageHistoryControls
          theme={this.state.theme}
          onChangeTheme={(t)=>{this.handleChangeTheme(t)}} />
        <HyperageHistoryContent
          theme={this.state.theme} />
        {this.state.themePopupVisible ? 
          <HyperageHistoryThemePopup
            theme={this.state.nextTheme} />
        : null }
      </div>
    );
  }
}