import React from 'react';
import {HyperwebContext, HyperwebContextReviews, HyperwebContextRandomPopup, HyperwebContextRandomImagePopups} from '../../contexts/HyperwebContext';
import HyperwebHeader from './HyperwebHeader';
import HyperwebLoader from './HyperwebLoader';
import HyperwebReviews from './HyperwebReviews';
import HyperwebPopups from './HyperwebPopups';
import HyperwebClippy from './HyperwebClippy';
import HyperwebAscii from './HyperwebAscii';
import HyperwebScreensavers from './HyperwebScreensavers';
import HyperwebConstructionBanner from './HyperwebConstructionBanner';
import HyperwebFontList from './HyperwebFontList';
import GlobalNav from '../Global/GlobalNav';
import Guid from 'guid';
import Arr from '../../utils/Arr';
import Calc from '../../utils/Calc';
import Track from '../../utils/Track';

export default class Hyperweb extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      loaded: false,
      textEditorPopups: [],
      imagePopups: [],
      reviews: HyperwebContextReviews,
      onClosePopup: (p)=>{this.handleClosePopup(p)},
      onAddPopup: (t,i)=>{this.addPopup(t,i)},
      onFocusPopup: (p)=>{this.handleFocusPopup(p)},
      onRotateBgImage: ()=>{this.rotateBgImage()},
      onClippyClick: ()=>{this.handleClippyClick()},
      popupZindex: 0,
      bgIndex: 0,
      clippyClicked: false,
      showClippyHelper: false
    }
    this.bgIndexTimer = null;
    this.clippyHelperTimer = null;
    this.randImgPopupTimer = null;
    this.randTextEditorPopupTimer = null;
  }
  componentDidMount() {
    if (!this.state.loaded) {
      setTimeout(()=>{
        this.setState({
          loaded: true
        });
        // this.resetBgImageTimer();
      }, 5200);
    } else {
      // this.resetBgImageTimer();
    }
    this.populatePopups();
    this.setupClippyHelper();
    Track.pageLoad();
  }
  componentWillUnmount() {
    clearTimeout(this.bgIndexTimer);
    clearTimeout(this.clippyHelperTimer);
    clearInterval(this.randImgPopupTimer);
    clearInterval(this.randTextEditorPopupTimer);
  }
  populatePopups(){
    const randImgPopups = HyperwebContextRandomImagePopups();
    Arr.times(3, (i)=>{
      setTimeout(()=>{
        this.addPopup('imagePopups', randImgPopups[i]);  
      }, 130);
    })
    Arr.times(3, (i)=>{
      setTimeout(()=>{
        this.addPopup('textEditorPopups');
      }, 120)
    })

    this.randImgPopupTimer = setInterval(()=>{
      this.addPopup('imagePopups');
    }, 70 * 1000);
    this.randTextEditorPopupTimer = setInterval(()=>{
      this.addPopup('textEditorPopups');
    }, 40 * 1000);
  }
  rotateBgImage(){
    this.setState({
      bgIndex: Calc.randomIntBetween(0,7)
    });
  }
  setupClippyHelper(){
    this.clippyHelperTimer = setTimeout(()=>{
      this.setState({
        showClippyHelper: true 
      });
    }, 20 * 1000);
  }
  handleClippyClick(){
    this.rotateBgImage();
    this.setState({
      clippyClicked: true
    });
  }
  addPopup(popupType, popupItem){
    let item = (popupItem || HyperwebContextRandomPopup(popupType))
    const zIndex = this.state.popupZindex + 1;
    let popups = this.state[popupType].concat([{
        id: Guid.create(),
        item: item,
        popupType: popupType,
        zIndex: zIndex
      }])
    if (popups.length > 20) {
      popups.shift();
    }
    this.setState({
      [popupType]: popups,
      popupZindex: zIndex
    });
  }
  handleFocusPopup(inPopup) {
    const zIndex = this.state.popupZindex + 1;
    const popups = this.state[inPopup.popupType].map((popup)=>{
      if (inPopup.id === popup.id) {
        return {
          ...popup,
          zIndex: zIndex
        }
      } else {
        return popup;
      }
    });
    this.setState({
      [inPopup.popupType]: popups,
      popupZindex: zIndex
    });
  }
  handleClosePopup(popup) {
    const popups = this.state[popup.popupType].filter((p)=>{
      return p.id !== popup.id
    });
    this.setState({
      [popup.popupType]: popups
    });
  }
  render() {
    const loadedClass = (this.state.loaded ? 'hyperweb--loaded' : 'hyperweb--loading');
    const bgFontFamily = (this.state.bgIndex === 0) ? 'Boxout' : null;
    return (
      <div 
        className={`hyperweb ${loadedClass} hyperweb--bg-${this.state.bgIndex}`}
        data-font-family={bgFontFamily} >
        
        <GlobalNav>
          <HyperwebHeader />
        </GlobalNav>

        <HyperwebContext.Provider value={this.state}>
          <HyperwebLoader />

          {this.state.loaded ? 
            <div className='hyperweb--main'>
              <div className='hyperweb--content'>
                <HyperwebFontList />
                <HyperwebReviews />
                <HyperwebAscii />
              </div>
              <HyperwebConstructionBanner />
              <HyperwebPopups />
              <HyperwebClippy />
              <HyperwebScreensavers />
            </div>
          : null }
        </HyperwebContext.Provider>
      </div>
    );
  }
}

// const BG_IMAGE_TIME = 60000;