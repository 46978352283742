import React from 'react';
import Arr from '../../utils/Arr';

export default class HomeTitle extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      font: Arr.shuffle(FONTS)[0]
    }
  }
  render() {
    return (
      <div className='hyperwarp--title'>
        <img 
          src={`/images/hyperwarp/hyperwarp-${this.state.font}.png`}
          alt='HyperWarp' />
      </div>
    );
  }
}

const FONTS = [
  'boxout',
  'afrikola',
  'agrippa',
  'blimey',
  'cheee',
  'civilitate',
  'digestive',
  'discordia',
  'eckmannpsych',
  'framboisier',
  'future-mono',
  'giboula',
  'gig',
  'goiters',
  'halunke',
  'hatchet',
  'kicker',
  'meek',
  'messer',
  'mollydooker',
  'nikolai',
  'nostra',
  'phantom',
  'rainer',
  'saiga',
  'silverspoon',
  'spindle',
  'super-fat-bob',
  'taters',
  'tweak',
  'urushi',
  'vieno',
  'wildberry',
  'xprmntl-02',
  'zangezi'
];