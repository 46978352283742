import React from 'react';
import Arr from '../../utils/Arr';

export default class HyperwebConstructionBanner extends React.Component {
  renderText() {
    return Arr.mapTimes(20, (i)=>{
      return (
        <span 
          key={`t-${i}`}>
          Under Construction
        </span>
      );
    })
  }
  render() {
    return (
      <div className='hyperweb--construction'>
        <div className='hyperweb--construction--text'>
          {this.renderText()}
        </div>
      </div>
    );
  }
}