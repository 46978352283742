import RawOptions from './HyperagePalmOptionsRaw.json';
import Str from '../utils/Str';

const prepAll = ()=>{
  let i = 0;
  return RawOptions.map((opt)=>{
    let newOpt = {
      ...opt,
      slug: Str.slugify(opt.label)
    }
    if (opt.type === 'number') {
      i += 1;
      return {
        ...newOpt,
        number: i
      }
    } else {
      return newOpt;
    }
  })
}

const all = prepAll();

const allOfType = (type)=>{
  return all.filter((opt)=>{
    return opt.type === type;
  })
}

export default {
  all,
  allOfType
}