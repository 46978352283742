import React from 'react';

export default class Info extends React.Component {

  render() {
    return (
      <div className='info'>
        <div className='info--spacer'></div>
        <div className='info--issueNo'>
          <h2>
            HyperText Issue
          </h2>
          <div className='info--issueNo--number'>
            01
          </div>
        </div>
        <div className='info--about'>
            ?<br />
            <p>
            HyperText is a celebration of the first year of releases from <a href='https://www.futurefonts.xyz?ref=674' target='_blank' rel="noopener noreferrer">Future&nbsp;Fonts</a>. Issue 01 is an exploration of type culture, utilizing pop culture references as a vehicle for a type specimen. We created three unique modules that play into aesthetics and overlooked histories of type design in the world.
            </p>
            <p>
              It aims to celebrate the diverse fonts and type designers that make this project so special. This catalog includes 70 fonts, made by 40 foundries, from 22 countries, released between February 2018 and February 2019.
            </p>
            <p>
              Looking forward to many more,<br />
              Future Fonts & Fisk
            </p>
        </div>
        <div className='info--credits'>
          $<br />
          <p>
            Thank you <a href='https://dropbox.design/' target='_blank' rel="noopener noreferrer"><strong>Dropbox Design</strong></a> for your generous support to make hypertext weirder and bigger.
          </p>
          <p>
            Thanks to all the designers, friends, and type lovers across disciplines that have supported future fonts from the begining with words of encouragement, helpful feedback, sharing the site, and of course buying fonts. you keep us going and make us better.
          </p>
        </div>
        <div className='info--spacer'></div>

        <div className='info--spacer'></div>
        <div className='info--sponsors'>
          
        </div>
        <div className='info--sponsors--about'>
          !<br />
          <p>
            <a href='https://www.futurefonts.xyz?ref=674' target='_blank' rel="noopener noreferrer">Future&nbsp;Fonts</a> is an experimental platform for in-progress typefaces. We publish contemporary, high-quality fonts, and aim to bridge the gap between the creators and users of type. Above all, we are a community of passionate designers and type lovers elevating each other to grow, learn, stay curious, and have fun.
          </p>
          <p>
            Future Fonts was created and is run by <a href='https://www.scribbletone.com' target='_blank' rel="noopener noreferrer">Scribble&nbsp;Tone</a>, a design studio and type foundry in Portland, Oregon. The team is led by Travis Kochel and Lizy Gershenzon, with help from Leah Maldonado and Ella Higgins.
          </p>
          <p>
            We are eternally grateful to James Edmondson, from <a href='https://ohnotype.co/' target='_blank' rel="noopener noreferrer">OHNO&nbsp;Type&nbsp;Co.</a>, for his role in bringing together the community and branding us. He continues to work with us as an advisor and ambassador.
          </p>
        </div>
        <div className='info--sponsors--about-2'>
            &<br />
            <p>
              This site was designed by <a href='https://www.fiskprojects.com' target='_blank' rel="noopener noreferrer">Fisk</a>, and coded by <a href='https://www.scribbletone.com' target='_blank' rel="noopener noreferrer">Scribble&nbsp;Tone</a>.
            </p>
            <p>
              <a href='https://www.fiskprojects.com' target='_blank' rel="noopener noreferrer">Fisk</a> is a graphic design studio, art gallery and store located in Portland, Oregon. HyperText was designed by Bijan Berahimi, Christine Shen, Huicheng Wu and Cole Johnson.
            </p>          
        </div>
        <div className='info--spacer'></div>
      </div>
    );
  }
}



        // <div className='info--spacer'></div>
        // <div className='info--hyperfood'>
        //   <h2>Module 01: HyperFood</h2> 
        // </div>
        // <div className='info--hyperfood--about'>
        //   [hyperfood notes]
        // </div>
        // <div className='info--hyperfood--fonts'>
        //   fonts used
        // </div>
        // <div className='info--spacer'></div>

        // <div className='info--spacer'></div>
        // <div className='info--hyperweb'>
        //   <h2>Module 02: HyperWeb</h2> 
        // </div>
        // <div className='info--hyperweb--about'>
        //   [hyperweb notes]
        // </div>
        // <div className='info--hyperweb--fonts'>
        //   fonts used
        // </div>
        // <div className='info--spacer'></div>

        // <div className='info--spacer'></div>
        // <div className='info--hyperage'>
        //   <h2>Module 03: HyperAge</h2> 
        // </div>
        // <div className='info--hyperage--about'>
        //   [hyperage notes]
        // </div>
        // <div className='info--hyperage--fonts'>
        //   fonts used
        // </div>
        // <div className='info--spacer'></div>