import React from 'react';

export default class HyperwebScreensaverDvd extends React.Component {
  render() {
    return (
      <div className='hyperweb--screensaver hyperweb--screensaver--dvd'>
        <div className='hyperweb--screensaver--dvd--x'>
          <div className='hyperweb--screensaver--dvd--y'>
            <div className='hyperweb--screensaver--dvd--text'>
            <span>Out to</span><br />
            <span>lunch</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}