import React from 'react';
import Arr from '../../utils/Arr';

export default class HyperageMouseAuraLight extends React.Component {

  renderColors(){
    return Arr.mapTimes(3, (i)=>{
      return (
        <div 
          key={`l-${i}`}
          className={`hyperage--mouseAuraLight--color hyperage--mouseAuraLight--${i}`} />
      );
    })
  }
  render(){
    return (
      <div className='hyperage--mouseAuraLight'>
        {this.renderColors()}
      </div>
    );
  }
}