import React from 'react';

export default class HyperwebCounter extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      hits: 3234879284
    }
  }
  hitsWithComma() {
    return new Intl.NumberFormat('en-IN', {}).format(this.state.hits);
  }
  render() {
    return (
      <div className='hyperweb--counter'>
          <div className='hyperweb--counter--box'>
            <div className='hyperweb--counter--title'>
              You are lucky visitor #
            </div>
            <div className='hyperweb--counter--number'>
              {this.hitsWithComma()}
            </div>
        </div>
      </div>
    );
  }
}