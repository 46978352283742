import Dom from './Dom';

function disable() {
  const htmlEl = Dom.findFirst('html');
  Dom.addClass(htmlEl, 'disableScroll');
}
function enable() {
  const htmlEl = Dom.findFirst('html');
  Dom.removeClass(htmlEl, 'disableScroll');
}
function disableInMobile() {
  const htmlEl = Dom.findFirst('html');
  Dom.addClass(htmlEl, 'disableScrollMobile'); 
}
function enableInMobile() {
  const htmlEl = Dom.findFirst('html');
  Dom.removeClass(htmlEl, 'disableScrollMobile');
}
function to(inTarget, opts={}) {
  const target = Dom.findFirst(inTarget);
  const offset = opts.offset || 0;
  const duration = opts.duration || 300;
  const delay = opts.delay || 0;
  if (target) {
    setTimeout(()=>{
      const targetY = target.getBoundingClientRect().top + window.scrollY;
      animateTo(targetY + offset, duration)
    }, delay);
  }
}
function animateTo(targetY, duration) {
  const startingY = window.pageYOffset  
  const diff = targetY - startingY;
  let start;

  window.requestAnimationFrame(function step(timestamp) {
    if (!start) {
      start = timestamp;
    }
    const time = timestamp - start;
    const percent = Math.min(time / duration, 1)

    window.scrollTo(0, startingY + diff * percent);

    if (time < duration) {
      window.requestAnimationFrame(step)
    }
  })
}

export default {
  disable,
  enable,
  disableInMobile,
  enableInMobile,
  to,
  animateTo
}