import React from 'react';
import HyperageMouseAuraLight from './HyperageMouseAuraLight';
import Guid from 'guid';

const ACTIVE = false;

export default class HyperageMouseAuraTrail extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      auras: []
    }
    this.timer = null;
    this.mouseX = 500;
    this.mouseY = 300;

    this.handleUpdateMousePosition = this.handleUpdateMousePosition.bind(this);
  }
  componentDidMount() {
    this.handleStartFollowing();
  }
  componentWillUnmount() {
    this.handleStopFollowing();
  }
  handleStartFollowing() {
    this.timer = setInterval(()=>{
      this.handleAddAura();
    }, INTERVAL);
    document.addEventListener('mousemove', this.handleUpdateMousePosition);
  }
  handleUpdateMousePosition(e) {
    this.mouseX = e.clientX; 
    this.mouseY = e.clientY;
  }
  handleStopFollowing() {
    clearInterval(this.timer);
    document.removeEventListener('mousemove', this.handleUpdateMousePosition);
  }
  handleAddAura(){
    let auras = this.state.auras.concat([{
      id: Guid.create(),
      x: this.mouseX,
      y: this.mouseY
    }]);
    if (auras.length > FRAMES_PER_FADE) {
      auras.shift();
    }
    this.setState({
      auras: auras
    });
  }
  renderAuras(){
    return this.state.auras.map((aura)=>{
      return (
        <div 
          key={aura.id}
          className='hyperage--mouseAuraTrail'
          style={{
            top: aura.y,
            left: aura.x
          }}>
          <HyperageMouseAuraLight />
        </div>
      );
    })
  }
  render() {
    if (ACTIVE) {
      return (
        <div className='hyperage--mouseAuraWrapper'>
          {this.renderAuras()}
        </div>
      );
    } else {
      return null;
    }
  }
}

const INTERVAL = 1000;
const FADE_LENGTH = 8;
const FRAMES_PER_FADE = (1000 / INTERVAL) * FADE_LENGTH;