import React from 'react';
import HyperfoodDblOutlinedText from './HyperfoodDblOutlinedText';

export default class HyperfoodFloater24hours extends React.Component {

  render() {
    return (
      <div className='hyperfood--floater--24hours'>
        <HyperfoodDblOutlinedText
          className='hyperfood--floater--24'
          text='24' />
        <div 
          className='hyperfood--floater--hours'>
          hours
        </div>
      </div>
    );
  }
}