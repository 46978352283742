import React from 'react';
import Guid from 'guid';
import Calc from '../../utils/Calc';
import HyperfoodHeader from './HyperfoodHeader';
import HyperfoodWelcome from './HyperfoodWelcome';
import HyperfoodMenu from './HyperfoodMenu';
import HyperfoodFloaters from './HyperfoodFloaters';
import {HyperfoodContext, HyperfoodContextFloaters} from '../../contexts/HyperfoodContext';
import HyperfoodCheckout from './HyperfoodCheckout';
import GlobalNav from '../Global/GlobalNav';
import HyperfoodSubmitMessage from './HyperfoodSubmitMessage';
import Track from '../../utils/Track';

export default class Hyperfood extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      cart: [],
      floaters: [],
      onAddItem: (i,t)=>{this.handleAddItem(i,t)},
      onRemoveItem: (i,t)=>{this.handleRemoveItem(i,t)},
      onSubmit: ()=>{this.handleSubmit()},
      onUnSubmit: ()=>{this.handleUnSubmit()},
      onAddRandomFloater: ()=>{this.addRandomFloater()},
      autoFloatersEnabled: true,
      submitted: false
    }
    this.cartTimer = null;
  }
  componentDidMount() {
    if (this.state.autoFloatersEnabled) {
      this.addRandomFloater();
      
      this.cartTimer = setInterval(()=>{
        this.addRandomFloater();
      }, 7000);
    }
    Track.pageLoad();
  }
  componentWillUnmount(nextProps, nextState) {
    clearInterval(this.cartTimer)
  }
  handleAddItem(item, itemType='cart'){
    let items = this.state[itemType].concat([{
        id: Guid.create(),
        startX: Calc.randomIntBetween(0,90),
        startY: Calc.randomIntBetween(0,90),
        startSize: Calc.randomIntBetween(35,40),
        floatIndex: Calc.randomIntBetween(1,4),
        item: item
      }])
    if (items.length > 50) {
      items.shift();
    }
    window.requestIdleCallback(()=>{
      this.setState({
        [itemType]: items
      });
    });
  }
  handleRemoveItem(itemToRemove, itemType='cart') {
    const items = this.state[itemType].filter((cartItem)=>{
      return cartItem.id !== itemToRemove.id;
    })
    this.setState({
      [itemType]: items
    });
  }
  handleSubmit() {
    this.setState({
      submitted: true
    });
  }
  handleUnSubmit() {
    this.setState({
      submitted: false 
    });
  }
  addRandomFloater(){
    const items = HyperfoodContextFloaters;
    const randIndex = Calc.randomIntBetween(0, items.length-1);
    // const randIndex = 2;
    const item = items[randIndex];
    const sizes = ['small', 'medium', 'large'];
    this.handleAddItem({
      ...item,
      size: sizes[Calc.randomIntBetween(0,2)]
    }, 'floaters');
  }

  render() {
    return (
      <div className='hyperfood'>
        <GlobalNav>
          <HyperfoodHeader />
        </GlobalNav>
        <HyperfoodContext.Provider value={this.state}>
          <HyperfoodWelcome />
          <HyperfoodMenu />
          <HyperfoodCheckout />
          <HyperfoodFloaters />
          <HyperfoodSubmitMessage />
        </HyperfoodContext.Provider>
      </div>
    );
  }
}