import React from 'react';
import FontData from '../../data/FontData.json';
import Arr from '../../utils/Arr';
import FontIndexRow from './FontIndexRow';

export default class FontIndex extends React.Component {
  renderRows(){
    const chunkedFonts = Arr.chunk(FontData, 3);
    return Arr.mapWithIndex(chunkedFonts, (fonts, i)=>{
      return (
        <FontIndexRow
          key={`firow-${i}`}
          index={i}
          isLast={i+1 === chunkedFonts.length}
          fonts={fonts}
          domLoaded={this.props.domLoaded} />
      );
    });
  }
  render() {
    return (
      <div className='fontIndex'>
        <div className='fontIndex--thumbnails'>
          {this.renderRows()}
        </div>
      </div>
    );
  }
}