import React from 'react';
import PropTypes from 'prop-types';
import {HyperageContext} from '../../contexts/HyperageContext';

export default class HyperageBreatheVisualizerFan extends React.Component {
  constructor(props){
    super(props);
    this.fanTimer = null;
  }
  componentDidMount() {
    this.fanTimer = setInterval(()=>{
      this.addPopups(2, 500);
    }, 20 * 1000);
  }
  componentWillUnmount() {
    clearInterval(this.fanTimer);
  }
  componentDidUpdate(prevProps, prevState) {
    if ((prevProps.cyclePct < .5) && (this.props.cyclePct >= .5)) {
      // up
      this.addPopups(3);
    }
    if ((prevProps.cyclePct > .5) && (this.props.cyclePct <= .5)) {
      // down
      this.addPopups(3);
    }
  }
  addPopups(num, inDelay, skipAnimate) {
    const delay = inDelay || 300;
    this.context.onAddFanPopup(skipAnimate);
    if (num > 1) {
      setTimeout(()=>{
        this.addPopups(num - 1, delay, skipAnimate);
      }, delay);
    }
    
  }
  style() {
    // const duration = 120 - (this.props.cyclePct * 119.5);
    // return {
    //   animationDuration: `${duration}s`
    // }
    const rotation = this.props.cyclePct * 360 * 4;
    return {
      transform: `rotate(${rotation}deg)`
    }

    // const duration = 120 - (this.props.cyclePct * 119.5);
    // let duration = '120s';
    // if (this.props.cyclePct < .25) {
    //   duration = '120s'
    // } else if (this.props.cyclePct < .5) {
    //   duration = '60s';
    // } else if (this.props.cyclePct < .75) {
    //   duration = '20s';
    // } else if (this.props.cyclePct <= 1) {
    //   duration = '1s';
    // }
    // return {
    //   animationDuration: duration
    // }

    // let rotation = 0;
    // if (this.props.cyclePct < .25) {
    //   rotation = 45;
    // } else if (this.props.cyclePct < .5) {
    //   rotation = 180;
    // } else if (this.props.cyclePct < .75) {
    //   rotation = 360;
    // } else if (this.props.cyclePct <= 1) {
    //   rotation = 720;
    // }
    // return {
    //   transform: `rotate(${rotation}deg)`
    // }
  }
  render() {
    return (
      <div className='hyperage--breathe--visualizer--fan'>
        <div 
          style={this.style()}
          className='hyperage--breathe--visualizer--fan--glyph'>
          <img 
            className='hyperage--breathe--visualizer--fan--glyphImg'
            src='/images/hyperage/hyperage-fan.svg' alt='fan' />
        </div>
      </div>
    );
  }
}

HyperageBreatheVisualizerFan.propTypes = {
  cyclePct: PropTypes.number,
  direction: PropTypes.string
};

HyperageBreatheVisualizerFan.contextType = HyperageContext;