import React from 'react';

export default class HyperfoodFloaterFreshlyBaked extends React.Component {

  render() {
    return (
      <div className='hyperfood--floater--freshlyBaked'>
        <span>Freshly</span><br />
        <span>Baked</span>
      </div>
    );
  }
}