import React from 'react';
import HyperagePalmOptions from './HyperagePalmOptions';
import HyperagePalmSelectedOption from './HyperagePalmSelectedOption';

export default class HyperagePalm extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      selectedOption: null,
      popupVisible: false
    }
    this.hidePopupTimer = null;
  }
  handleOptionSelect(option) {
    this.setState({
      selectedOption: option,
      popupVisible: false
    });

    setTimeout(()=>{
      this.setState({
        popupVisible: true
      });
      this.queueClosePopup();
    }, 50);
  }
  handleClearSelected() {
    this.setState({
      selectedOption: null
    });
  }
  queueClosePopup(){
    clearTimeout(this.hidePopupTimer);
    this.hidePopupTimer = setTimeout(()=>{
      this.setState({
        popupVisible: false
      });
    }, 4000);
  }
  optionIsSelected(option) {
    return this.state.selectedOption && this.state.selectedOption.slug === option.slug;
  }
  render() {
    return (
      <div className='hyperage--palm'>
        <div 
          onClick={()=>{this.handleClearSelected()}}
          className='hyperage--palmBg'>
        </div>
        <HyperagePalmOptions
          onOptionSelect={(o)=>{this.handleOptionSelect(o)}} />

        {this.state.popupVisible ?
          <HyperagePalmSelectedOption
            selectedOption={this.state.selectedOption} />
        : null}
      </div>
    );
  }
}