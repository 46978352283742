import React from 'react';
import PropTypes from 'prop-types';
import {HyperfoodContext, HyperfoodFindFontStyleForItem} from '../../contexts/HyperfoodContext';

export default class HyperfoodCheckoutItem extends React.Component {
  render() {
    return (
      <div className='hyperfood--checkoutItem'>
        <div className='hyperfood--checkoutItemIndex'>
          {this.props.index + 1}
        </div> 
        <div 
          className={`hyperfood--checkoutItemName hyperfood--checkoutItemName--${this.props.cartItem.item.fontFamilyClass}`}
          style={HyperfoodFindFontStyleForItem(this.props.cartItem.item)}>
          {this.props.cartItem.item.label}
        </div>
        <div className='hyperfood--checkoutItemCost'>
          ${this.props.cartItem.item.cost}
        </div>
      </div>
    );
  }
}

HyperfoodCheckoutItem.propTypes = {
  cartItem: PropTypes.object,
  index: PropTypes.number
};

HyperfoodCheckoutItem.contextType = HyperfoodContext;