import React from 'react';
import Str from '../../utils/Str';
import FontData from '../../data/FontData';
import { withRouter } from "react-router";
import Arr from '../../utils/Arr';
import FontInspectorHistory from './FontInspectorHistory';

class FontInspector extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      active: false,
      font: null,
      fontHistory: []
    }
    this.updateTimer = null;
    this.targetEl = null;
    this.mouseX = 500;
    this.mouseY = 300;
    this.handleUpdateMousePosition = this.handleUpdateMousePosition.bind(this);
  }
  handleToggle() {
    if (this.state.active) {
      this.stopWatching();
    } else {
      this.startWatching();
    }
    this.props.onFontInspectorChange(!this.state.active);
    this.setState({
      active: !this.state.active
    });
  }
  startWatching() {
    this.updateTimer = setInterval(()=>{
      this.handleUpdateFont();
    }, INTERVAL);
    document.addEventListener('mousemove', this.handleUpdateMousePosition);
  }
  stopWatching() {
    clearInterval(this.updateTimer);
    document.removeEventListener('mousemove', this.handleUpdateMousePosition);
  }
  handleUpdateMousePosition(e) {
    if (e.target && !(e.target.dataset && e.target.dataset.ignoreInspector)) {
      this.mouseX = e.clientX;
      this.mouseY = e.clientY;
      this.targetEl = e.target;
    }
  }
  handleUpdateFont() {
    const font = this.getFont(this.targetEl);
    const newFontHistory = 
      Arr.uniqBy(
        Arr.removeEmpty(
          [font].concat(this.state.fontHistory)
        ),
        'fontSlug'
      );
    this.setState({
      font: font,
      fontHistory: newFontHistory
    });
  }
  getFont(el) {
    if (el) {
      const slug = Str.slugify(this.getHoveringFamily(el));
      const font = FontData.find((f)=>{
        return (f.fontSlug === slug) || (f.altSlugs && (f.altSlugs.indexOf(slug) > -1));
      });
      return font;
    } else {
      return null;
    }
  }
  getHoveringFamily(el) {
    if (el.dataset.fontFamily) {
      return el.dataset.fontFamily;
    } else {
      const s = getComputedStyle(el);
      return Str.replaceAll(s.fontFamily, '"', '').replace(' VF', '');
    }
  }
  style() {
    const w = window.innerWidth;
    const h = window.innerHeight;
    let x = {left: this.mouseX - 20};
    let y = {top: this.mouseY + 20};
    if (this.mouseX > (w * 0.75)) {
      x = {right: w - this.mouseX - 30};
    }
    if (this.mouseY > (h * 0.75)) {
      y = {bottom: h - this.mouseY + 15};
    }
    return {
      ...x,
      ...y
    }
  }
  render() {
    const activeClass = this.state.active ? 'fontInspector--active' : 'fontInspector--inactive';
    return [
      <FontInspectorHistory
        key='fontInspectorHistory'
        active={this.state.active}
        fontHistory={this.state.fontHistory}
        onClose={()=>{this.handleToggle()}}
         />,
      <div 
        key='fontInspector'
        className={`fontInspector ${activeClass} path--${this.props.location.pathname.replace('/','')}`}>
        <div className='fontInspector--btnRow'>
          <div className='fontInspector--label'>
            {this.state.active ? 'Close' : 'Open'} Font Inspector
          </div>
          <button
            alt='Font Inspector' 
            className='fontInspector--toggleBtn'
            onClick={()=>{this.handleToggle()}}>
            i
          </button>
        </div>
        {(this.state.active && this.state.font) ? 
          <div 
            style={this.style()}
            className='fontInspector--value'>
            {this.state.font.fontName} by {this.state.font.foundryName}
          </div>
        : null }
      </div>
    ];
  }
}

const INTERVAL = 60;

export default withRouter(FontInspector);