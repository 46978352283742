import React from 'react';

export default class HyperwebAdCoolType extends React.Component {

  render() {
    return (
      <a 
        target='_blank'
        rel="noopener noreferrer"
        href='https://www.futurefonts.xyz?ref=674'
        className='hyperwebAd--coolType'>
        <div className='hyperwebAd--coolType--main'>
          Need Cool Type?
        </div>
        <div className='hyperwebAd--coolType--url'>
          Visit futurefonts.xyz
        </div>
      </a>
    );
  }
}