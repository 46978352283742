import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import Arr from '../../utils/Arr';
import Scroll from '../../utils/Scroll';
import AnimateHeight from 'react-animate-height';

export default class HomeNavItem extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      collapsed: this.props.collapsed
    }
  }
  componentDidMount() {
    if (this.isActivePage() && window.location.pathname.split('/').length === 2) {
      setTimeout(()=>{
        Scroll.to(`.${this.hrefClass()}`, {duration: 400});
      }, 1000);
    }
  }
  isActivePage() {
    return window.location.pathname.split('/').indexOf(this.props.href.replace('/', '')) > -1;
  }
  handleLinkClick(e){
    if (this.props.children) {
      if (this.isActivePage()) {
        e.preventDefault();  
        this.props.history.push('/');
      }
      setTimeout(()=>{
        Scroll.to(`.${this.hrefClass()}`, {duration: 400});
      }, SLIDE_IN_SPEED);
    }
  }
  hrefClass() {
    return 'home--navItem--' + this.props.href.replace('/', '');
  }
  renderHoverText(){
    const hoverText = this.props.hoverText || this.props.title;

    return Arr.mapTimes(10, (i)=>{
      return (
        <span key={`ni-${i}`}>
          {hoverText}
        </span>
      );
    })
  }
  render() {
    const domLoadedClass = this.props.domLoaded ? 'domLoaded' : '';
    return (
      <div className={`home--navItem ${this.hrefClass()} ${this.props.className} ${domLoadedClass}`}>
        <Link 
          className='home--navItemLink'
          to={this.props.href}
          onClick={(e)=>{this.handleLinkClick(e)}}>
          <div className='home--navItemLink--text'>
            {this.props.title}
          </div>
          <div className='home--navItemLink--hoverText'>
            {this.renderHoverText()}
          </div>
        </Link>
        {this.props.children ?
          <AnimateHeight
            duration={SLIDE_IN_SPEED}
            height={this.isActivePage() ? 'auto' : 0}>
            {this.isActivePage() ? 
              this.props.children
            : <div />}
          </AnimateHeight>
        : null}
      </div>
    );
  }
}

HomeNavItem.propTypes = {
  className: PropTypes.string,
  collapsibleContent: PropTypes.object,
  collapsed: PropTypes.bool,
  title: PropTypes.string,
  href: PropTypes.string
};
HomeNavItem.defaultProps = {
  className: '',
  collapsed: true
};

const SLIDE_IN_SPEED = 200;