import React from 'react';
import IdleTimer from 'react-idle-timer';
import Calc from '../../utils/Calc';
import HyperwebScreensaver from './HyperwebScreensaver';

export default class HyperwebScreensavers extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      asleep: false,
      screensaverId: this.randomScreensaverId()
    }
  }
  randomScreensaverId(){
    return SCREENSAVER_IDS[Calc.randomIntBetween(0, SCREENSAVER_IDS.length - 1)];
  }
  handleAwaken() {
    this.setState({
      asleep: false
    });
  }
  handleSleep() {
    this.setState({
      asleep: true,
      screensaverId: this.randomScreensaverId()
    });
  }
  render() {
    return (
      <div className='hyperweb--screensaverWrapper'>
        <IdleTimer
          element={document}
          onActive={()=>{this.handleAwaken()}}
          onIdle={()=>{this.handleSleep()}}
          debounce={250}
          timeout={SLEEP_TIME} />
        {this.state.asleep ? 
          <HyperwebScreensaver
            id={this.state.screensaverId} />
        : null}
      </div>
    );  
  }
}

const SLEEP_TIME = 1000 * 45;
// const SLEEP_TIME = 1000;
const SCREENSAVER_IDS = ['flyingLetters', 'dvd', '3dText'];
// const SCREENSAVER_IDS = ['3dText'];