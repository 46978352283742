import React from 'react';
import Hexagon from '../Shapes/Hexagon';
import Calc from '../../utils/Calc';

export default class HyperfoodFloaterPlsOrderHere extends React.Component {
  constructor(props){
    super(props);
    const colors = ['Blue', 'Red'];

    this.state = {
      color: colors[ Calc.randomIntBetween(0,(colors.length - 1))]
    }
  }
  render() {
    return (
      <div className={`hyperfood--floater--plsOrderHere hyperfood--floater--plsOrderHere${this.state.color}`}>
        <Hexagon />
        <div className='hyperfood--floater--plsOrderHereText'>
          Please<br />Order<br />Here
        </div>
      </div>
    );
  }
}