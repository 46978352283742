import React from 'react';
import PropTypes from 'prop-types';

export default class HyperageHistoryContent extends React.Component {

  render() {
    return (
      <div className='hyperage--history--content'>
        <div className='hyperage--history--logo'>
          <span>
            Origin
          </span>
          <span>
            Origin
          </span>
        </div>
        <div className='hyperage--history--header hyperage--history--header--preClassical'>
          <span>
            Pre-Classical Period
          </span>
          <span>
            Pre-Classical Period
          </span>
        </div>
        <div className='hyperage--history--p1'>
          
          <img src='/images/hyperage/hyperage-symbol-15-2-a.svg' alt='' />
          <p>

            The beginnings of our society were developed by an ancient civilization over 5,000 years ago. 

            The first words behind our practice were first mentioned in old, sacred texts. These texts are a collection of songs, mantras and rituals to be used by spiritual leaders and taught to students. They have slowly been refined and developed by mystic seers who have documented the practices and beliefs in various scriptures dating back to 500 B.C.E. They hold foundational ideas such as ritual sacrifice, ego networking, body painting and needling.
          </p>
        </div>
        <div className='hyperage--history--header hyperage--history--header--preClassical'>
          <span>
            Post-Classical Development
          </span>
          <span>
            Post-Classical Development
          </span>
        </div>
        <div className='hyperage--history--p2'>
          <img src='/images/hyperage/hyperage-symbol-3-2-a.svg' alt='' />
          <p>
            <span className='hyperage--history--p2--corner-1' />
              A few centuries later, our masters created a system of practices designed to rejuvenate the body and prolong life. They rejected the teachings of the ancient scriptures and embraced the physical body as the means to achieve enlightenment. They developed powerful radical techniques to cleanse the body and mind to break the knots that bind us to our physical existence. This exploration of these physical-spiritual  connections and body <span className='hyperage--history--p2--corner-2' /> centered practices led to the creation of our current-day practice.
          </p>
        </div>
        <div className='hyperage--history--header'>
          <span>
            Post-Classical&nbsp;&nbsp;
          </span>
          <span>
            Post-Classical&nbsp;&nbsp;
          </span>
        </div>
        <div className='hyperage--history--p3'>
          <img src='/images/hyperage/hyperage-symbol-14-2-a.svg' alt='' />
          <p>
            In the late 1800s and early 1900s, our masters began to travel to the West, attracting attention and followers. This began at the 1893 Parliament of Religions in Chicago, when our leaders wowed attendees with lectures on universality of the world’s religions. In the 1920s and 30s, we were strongly promoted in India and across Asia. Our first school was opened in Kerala in 1944 and in 1966 the Stretch Ur Mind Society was designed along the banks of the Godavari River.
          </p>
        </div>
      </div>
    );
  }
}

HyperageHistoryContent.propTypes = {
  theme: PropTypes.number
};
