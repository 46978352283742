import React from 'react';

export default class HyperfoodFloaterDunkel2 extends React.Component {

  render() {
    return (
      <div className='hyperfood--floater--dunkel2'>
        맛있는&nbsp;음식
      </div>
    );
  }
}
// Translates to: tasty food