import React from 'react';
import PropTypes from 'prop-types';

export default class FontInspectorHistory extends React.Component {
  renderList() {
    return this.props.fontHistory.map((font)=>{
      return (
        <div 
          key={font.fontSlug}
          data-ignore-inspector={true}
          className={`fontInspectorHistory--font`}>
          <div 
            data-ignore-inspector={true}
            className='fontInspectorHistory--font--name'>
            {font.fontName}
          </div>
          <div 
            data-ignore-inspector={true}
            className='fontInspectorHistory--font--foundry'>
            by {font.foundryName}
          </div>
          <a 
            data-ignore-inspector={true}
            target="_blank"
            rel="noopener noreferrer"
            href={`https://www.futurefonts.xyz/${font.foundrySlug}/${font.fontSlug}?ref=673`}
            className='fontInspectorHistory--buyLink'>
            More Info
          </a>
        </div>
      ) 
    })
  }
  render() {
    if (this.props.active) {
      return (
        <div 
          data-ignore-inspector={true}
          className='fontInspectorHistory'>
          <div
            data-ignore-inspector={true}
            className='fontInspectorHistory--list'>
            {this.renderList()}
          </div>
          <div 
            data-ignore-inspector={true}
            onClick={()=>{this.props.onClose()}}
            className='fontInspectorHistory--close' >
            X
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

FontInspectorHistory.propTypes = {
  fontHistory: PropTypes.array,
  active: PropTypes.bool
};
