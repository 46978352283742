import React from 'react';

export default class HyperageBreatheVisualizerStretch extends React.Component {
  renderVariableStyles() {
    const vals = VAR_AXES.map((axis)=>{
      const range = (axis.maxVal - axis.minVal);
      const val = range * this.props.cyclePct + axis.minVal;
      // debugger
      return `'${axis.key}' ${val}`
    })
    const scale = (this.props.cyclePct * .2) + .6;
    return {
      fontVariationSettings: vals.join(', '),
      transform: `scale(${scale},1)`
    }

  }
  render() {
    return (
      <div className='hyperage--breathe--visualizer--stretch'>
        <div 
          style={this.renderVariableStyles()}
          className='hyperage--breathe--visualizer--stretch--text'>
          <span>
            Stretch
          </span>
          <span>
            Stretch
          </span>
        </div>
      </div>
    );
  }
}

const VAR_AXES = [
  {
    key: 'wght',
    minVal: 0,
    maxVal: 1000
  }
];