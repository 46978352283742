import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

export default class FontIndexDetailHeader extends React.Component {

  render() {
    return (
      <div className='fontIndex--detail--header'>
        <div className='fontIndex--detail--header--fontName'>
          {this.props.font.fontName}
        </div>
        <div className='fontIndex--detail--header--foundry'>
          By {this.props.font.foundryName}
        </div>
        <a
          className='fontIndex--detail--header--buy'
          rel="noopener noreferrer"
          target="_blank"
          href={`https://www.futurefonts.xyz/${this.props.font.foundrySlug}/${this.props.font.fontSlug}?ref=672`}>
          <span>
            Buy
          </span>
        </a>
        <Link 
          to="/font-index"
          className='fontIndex--detail--headerClose'>
          <span>x</span>
        </Link>
      </div>
    );
  }
}

FontIndexDetailHeader.propTypes = {
  font: PropTypes.object
};
