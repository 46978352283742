import React from 'react';

export default class HyperfoodFloater99cents extends React.Component {

  render() {
    return (
      <div className='hyperfood--floater--99cents'>
        <span className='hyperfood--floater--99cents--99'>99</span><span className='hyperfood--floater--99cents--cent'>¢</span>
      </div>
    );
  }
}