import React from 'react';
import {HyperwebContext, HyperwebReviewsAdNames} from '../../contexts/HyperwebContext';
import HyperwebReview from './HyperwebReview';
import Arr from '../../utils/Arr';
import HyperwebAds from './HyperwebAds';

export default class HyperwebReviews extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      list: []
    }
  }
  componentDidMount() {
    this.setState({
      list: this.buildReviewsWithAds()
    });
  }
  buildAds() {
    return HyperwebReviewsAdNames.map((name)=>{
      return (
        <HyperwebAds 
          key={name}
          name={name} />
      );
    })
  }
  buildReviews() {
    return Arr.mapWithIndex(this.context.reviews, (review, i)=>{
      return (
        <HyperwebReview
          key={`review ${i}`}
          review={review} />
      )
    }); 
  }
  buildReviewsWithAds(){
    return Arr.shuffle(this.buildAds().concat(this.buildReviews()));
  }
  render() {
    return (
      <div className='hyperweb--reviews'>
        <div className='hyperweb--columnHeader'>
          Font Reviews
        </div>
        <div className='hyperweb--reviews--list'>
          <div className='hyperweb--reviews--col-1'>
            {this.state.list}
          </div>
        </div>
      </div>
    );
  }
}

HyperwebReviews.contextType = HyperwebContext;