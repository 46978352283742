import React from 'react';
import PropTypes from 'prop-types';
import HyperfoodMenuItem from './HyperfoodMenuItem';


export default class HyperfoodCartItem extends React.Component {

  render() {
    return (
      <div 
        className={`hyperfood--floater hyperfood--floater--float${this.props.cartItem.floatIndex}`}
        style={{
          left: `${this.props.cartItem.startX}vw`, 
          top: `${this.props.cartItem.startY}vh`,
          width: `${this.props.cartItem.startSize}rem`
        }} >
        <HyperfoodMenuItem
          item={this.props.cartItem.item}
          size='small'
          onItemClick={()=>{this.props.onItemRemove()}} />
      </div>
    );
  }
}

HyperfoodCartItem.propTypes = {
  cartItem: PropTypes.object.isRequired,
  startX: PropTypes.number,
  startY: PropTypes.number
};

HyperfoodCartItem.defaultProps = {
  startX: 0,
  startY: 0
};

