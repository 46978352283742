import React from 'react';

export default class HyperfoodFloaterYummy extends React.Component {

  render() {
    return (
      <div className='hyperfood--floater--yummy'>
        Yummy!
      </div>
    );
  }
}