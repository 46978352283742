import React from 'react';
import Str from '../utils/Str';
import Numeral from 'numeral';

const HyperfoodMenuFonts = [
  {
    fontFamily: 'Silverspoon',
    fontWeight: 600,
    fontStyle: 'italic'
  },
  {
    fontFamily: 'Hatchet Display',
    fontWeight: 600,
    fontStyle: 'normal'
  },
  {
    fontFamily: 'Blimey VF',
    fontWeight: 800,
    fontStyle: 'normal',
    fontVariationSettings: "'wght' 800"
  },
  {
    fontFamily: 'OHNO Blazeface Italic',
    fontWeight: 'normal'
  },
  {
    fontFamily: 'Coniferous',
    fontWeight: 800,
    fontStyle: 'normal'
  },
  {
    fontFamily: 'Wildberry',
    fontWeight: 800,
    fontStyle: 'normal'
  },
  {
    fontFamily: 'Taters',
    fontWeight: 800,
    fontStyle: 'normal'
  },
  {
    fontFamily: 'ZC Casual',
    fontWeight: 'normal',
    fontStyle: 'normal'
  },
  {
    fontFamily: 'Cheee VF',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontVariationSettings: "'grvt' 0, 'yest' 0"
  },
  {
    fontFamily: 'Ricochet',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontFeatureSettings: 'SWSH'
  },
  {
    fontFamily: 'Discordia',
    fontWeight: 'bold',
    fontStyle: 'normal'
  },
  {
    fontFamily: 'Messer Condensed',
    fontWeight: 'normal',
    fontStyle: 'normal'
  },
  {
    fontFamily: 'Meek Display',
    fontWeight: '800',
    fontStyle: 'normal'
  },
  {
    fontFamily: 'Saiga',
    fontWeight: '800',
    fontStyle: 'normal'
  },
  {
    fontFamily: 'Goiters',
    fontWeight: '800',
    fontStyle: 'normal'
  }
].map((f)=>{
  return {
    ...f,
    fontFamilyClass: Str.replaceAll(f.fontFamily, ' ', '-')
  }
})

const HyperfoodContextItems = [
  {
    name: 'cola',
    label: 'HyperCola',
    koreanLabel: '하이퍼 햄버거',
    filename: 'hyperfood-Cola.png',
    cost: .99,
    orientation: 'vertical',
    fontFamily: 'Meek Display'
  },
  {
    name: 'burger',
    label: 'HyperBurger',
    koreanLabel: '하이퍼 소고기',
    filename: 'hyperfood-Burger.png',
    cost: 5.99,
    orientation: 'diagonal',
    fontFamily: 'Goiters'
  },
  {
    name: 'scallion',
    label: 'HyperScallion',
    koreanLabel: '하이퍼 파',
    filename: 'hyperfood-Scallion.png',
    cost: 9.99,
    orientation: 'diagonal',
    fontFamily: 'Coniferous'
  },
  {
    name: 'jello',
    label: 'HyperJello',
    koreanLabel: '하이퍼 뽀키',
    filename: 'hyperfood-Jello.png',
    cost: 3.99,
    orientation: 'diagonal',
    fontFamily: 'OHNO Blazeface Italic'
  },
  {
    name: 'eggs',
    label: 'HyperEggs',
    koreanLabel: '하이퍼 달걀',
    filename: 'hyperfood-Eggs.png',
    cost: 4.99,
    orientation: 'diagonal',
    fontFamily: 'Ricochet'
  },
  {
    name: 'fish',
    label: 'HyperFish',
    koreanLabel: '하이퍼 포카리',
    filename: 'hyperfood-Fish.png',
    cost: 19.99,
    orientation: 'vertical',
    fontFamily: 'Silverspoon'
  },
  {
    name: 'peach',
    label: 'HyperPeach',
    koreanLabel: '하이퍼 상추',
    filename: 'hyperfood-Peach.png',
    cost: 2.99,
    orientation: 'vertical',
    fontFamily: 'Hatchet Display'
  },
  {
    name: 'broccoli',
    label: 'HyperBroccoli',
    koreanLabel: '하이퍼 파',
    filename: 'hyperfood-Broccoli-2.png',
    cost: 3.99,
    orientation: 'diagonal',
    fontFamily: 'ZC Casual'
  },
  {
    name: 'jellyroll',
    label: 'HyperJellyroll',
    koreanLabel: '하이퍼 파',
    filename: 'hyperfood-Jellyroll-2.png',
    cost: 6.99,
    orientation: 'diagonal',
    fontFamily: 'Taters'
  },
  {
    name: 'banana',
    label: 'HyperBanana',
    koreanLabel: '하이퍼 파',
    filename: 'hyperfood-Banana-2.png',
    cost: 1.99,
    orientation: 'diagonal',
    fontFamily: 'Wildberry'
  }
].map((i)=>{
  return {
    ...i,
    fontFamilyClass: Str.replaceAll(i.fontFamily, ' ', '-')
  }
})

const HyperfoodContextFloaters = [
  {
    name: 'plsOrderHere'
  },
  {
    name: 'no1'
  },
  {
    name: '24hours'
  },
  {
    name: 'fries'
  },
  {
    name: 'xl'
  },
  {
    name: 'dunkel1'
  },
  {
    name: 'dunkel2'
  },
  {
    name: '2018'
  },
  {
    name: 'freshlyBaked'
  },
  {
    name: 'buyNow'
  },
  {
    name: 'quality'
  },
  {
    name: '99cents'
  },
  {
    name: 'votedBest'
  },
  {
    name: 'sale'
  },
  {
    name: 'yummy'
  }
]
// const HyperfoodContextFloaters = [
//   {
//     name: 'yummy'
//   }
// ]


const findItem = (name)=>{
  return HyperfoodContextItems.find((item)=>{
    return name === item.name;
  })
}

const shortMenu = [
  {
    foodType: 'cola',
    size: 'large',
    minViewport: 0
  },
  {
    foodType: 'burger',
    size: 'medium'
  },
  {
    foodType: 'scallion',
    size: 'medium'
  },
  {
    foodType: 'jello',
    size: 'medium'
  },
  {
    foodType: 'eggs',
    size: 'medium'
  },
  {
    foodType: 'fish',
    size: 'large',
    extraClass: 'hyperfood--menuItem--lastRow'
  },
  {
    foodType: 'peach',
    size: 'large',
    extraClass: 'hyperfood--menuItem--lastRow'
  },
  {
    foodType: 'broccoli',
    size: 'medium'
  },
  {
    foodType: 'jellyroll',
    size: 'medium'
  },
  {
    foodType: 'banana',
    size: 'medium'
  }
]

const buildFullMenu = ()=>{
  return shortMenu.map((shortItem)=>{
    const item = findItem(shortItem.foodType);
    return {
      ...shortItem,
      ...item
    }
  })
}
const HyperfoodTotalCost = (cartItems)=>{
  const total = cartItems.map((cartItem)=>{
    return cartItem.item.cost;
  }).reduce((partial_sum, a) => partial_sum + a,0); 
  return Numeral(total).format('0,0.00');
}
const HyperfoodFindFontStyle = (fontFamily)=>{
  return HyperfoodMenuFonts.find((f) => {
    return f.fontFamily === fontFamily;
  })
}
const HyperfoodFindFontStyleForItem = (item)=>{
  return HyperfoodFindFontStyle(item.fontFamily);
}
const HyperfoodContextMenu = buildFullMenu();
const HyperfoodContext = React.createContext({});

export {
  HyperfoodContext,
  HyperfoodContextItems,
  HyperfoodContextMenu,
  HyperfoodTotalCost,
  HyperfoodContextFloaters,
  HyperfoodFindFontStyle,
  HyperfoodFindFontStyleForItem
}
