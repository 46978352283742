import React from 'react';
import {Link} from 'react-router-dom';
import HyperageFanGradientEye from '../Hyperage/HyperageFanGradientEye';

export default class HyperwebAdHyperage extends React.Component {

  render() {
    return (
      <Link
        to='/hyperage'
        className='hyperwebAd--hyperage'>
        <HyperageFanGradientEye 
          theme={4} />
        <div className='hyperwebAd--hyperage--content'>
          <div className='hyperwebAd--hyperage--top'>
            What is your definition of success?
          </div>
          <div className='hyperwebAd--hyperage--main'>
            <div className='hyperwebAd--hyperage--message'>
              As the palm leaves crumble in our minds we still remember our birth and beginnings as humankind. We remember the past and collectively imagine the future. Where does our past begin?
            </div>
            <div className='hyperwebAd--hyperage--phone'>
              Dial 1-80H-YPE-RAGE
            </div>
            <img 
              className='hyperwebAd--hyperage--bijan'
              src='/images/hyperage/hyperage-yoga-3.png' 
              alt='hyperage' />
          </div>
        </div>
      </Link>
    );
  }
}