import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-rangeslider';

export default class HyperageBreatheControls extends React.Component {
  text() {
    switch(this.props.theme) {
      case 'breathe':
        return ['Breathe In', 'Breathe Out'];
      case 'fan':
        return ['Push In', 'Push Out'];
      case 'stretch':
      default:
        return ['Hold', 'Release'];
    }
  }
  topText() {
    return this.text()[0];
  }
  botText() {
    return this.text()[1];
  }
  render() {
    return (
      <div className='hyperage--breathe--controls'>
        <div className='hyperage--breathe--controlsCapTop'>
          {this.topText()}
        </div>
        <div className='hyperage--breathe--controls--sliderWrapper'>
          <Slider
            onChangeComplete={this.props.onSliderInputChangeComplete}
            onChange={this.props.onSliderInputChange}
            className='hyperage--breathe--slider'
            value={100 - this.props.cyclePct * 100} 
            orientation='vertical'
            tooltip={false}
             />
        </div>
        <div className='hyperage--breathe--controlsCapBottom'>
          {this.botText()}
        </div>
      </div>
    );
  }
}

HyperageBreatheControls.propTypes = {
  onSliderInputMouseUp: PropTypes.func,
  onSliderInputMouseDown: PropTypes.func,
  onSliderInputChange: PropTypes.func,
  cyclePct: PropTypes.number
};


