import React from 'react';
import PropTypes from 'prop-types';
import Bowser from "bowser";

export default class BrowserWarning extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      browserWarningAccepted: false,
      supportedBrowser: this.supportedBrowser()
    }
  }
  handleProceed(){
    this.setState({
      browserWarningAccepted: true
    });
  }
  supportedBrowser() {
    // what are unsupported browsers?
    const browser = Bowser.getParser(window.navigator.userAgent);
    return browser.satisfies({
      chrome: ">=66",
      firefox: ">=62",
      opera: ">=53",
      safari: ">=11",
      edge: ">=17"
    });
  }
  render() {
    const supportedBrowserClass = this.supportedBrowser() ? 'supportedBrowser' : 'unsupportedBrowser';
    const acceptedClass = this.state.browserWarningAccepted ? 'browserWarningAccepted' : '';
    return (
      <div 
        data-ignore-inspector={true}
        className={`siteWrapper ${supportedBrowserClass} ${acceptedClass} ${this.props.className}`}>
        <div 
          data-ignore-inspector={true}
          className='siteWrapperContent'>
          {this.props.children}
        </div>
        {this.state.browserWarningAccepted ? 
          null
        :
          <div className='browserWarning'>
            <div className='browserWarning--box'>
              <div className='browserWarning--topStripes'>
                &nbsp;
              </div>
              <div className='browserWarning--content'>
                <h1>Warning</h1>
                <p>
                  This is an experimental website and is meant to be viewed on large screens and newer browsers. Things may be broken or look poorly. 
                </p>
                <button onClick={()=>{this.handleProceed()}}>
                  Proceed Anyway
                </button>
                <p>
                  Or visit the <a href='https://www.futurefonts.xyz?ref=674' target="_blank" rel="noopener noreferrer">Future Fonts</a> website instead.
                </p>
              </div>
              <div className='browserWarning--bottomStripes'>
                &nbsp;
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

BrowserWarning.propTypes = {
  className: PropTypes.string
};

BrowserWarning.defaultProps = {
  className: ''
};
