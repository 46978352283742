import React from 'react';
import SideScroller from '../Scrollers/SideScroller';

export default class HyperfoodWelcome extends React.Component {
  render() {
    return (
      <div className='hyperfood--welcome'>
        <div className='hyperfood--logoSection'>
          <img 
            className='hyperfood--logoImg' 
            src='/images/hyperfood/hyperfood-Logo2.svg'
            alt='hyperfood'
            data-font-family='Funkford' />
        </div>
        <div className='hyperfood--takeYourOrder'>
          <SideScroller 
            className='hyperfood--takeYourOrder--korean'
            text='하이퍼푸드에 오신 것을 환영합니다. 주문을 하시겠습니까?' />
          <SideScroller 
            className='hyperfood--takeYourOrder--english'
            text='Welcome to Hyperfood, may I take your order' />
        </div>
      </div>
    );
  }
}