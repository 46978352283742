import React from 'react';

export default class HyperfoodFloaterXl extends React.Component {

  render() {
    return (
      <div className='hyperfood--floater--xl'>
        XL?
      </div>
    );
  }
}