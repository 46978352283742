import Obj from './Obj';

function isEqual(arr1, arr2) {
  return Obj.isEqual(arr1, arr2);
}
function shuffle(inArr) {
  let a = [...inArr];
  for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}
function uniq(inArr) {
  return inArr.filter((elem, pos, newArr) => {
    return newArr.indexOf(elem) === pos;
  });
}
function uniqBy(inArr, prop){
  return inArr.filter((obj, pos, arr) => {
    return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
  });
}
function times(n, task){
  [...Array(n)].forEach((x,i)=>{
    task(i)
  })
}
function mapTimes(n, task){
  return [...Array(n)].map((x,i)=>{
    return task(i);
  })
}
function concatTimes(n, sourceArray){
  let a = [];
  [...Array(n)].forEach((x,i)=>{
    a = a.concat(sourceArray);
  })
  return a;
}
function concatFuncTimes(n, task) {
  let a = [];
  [...Array(n)].forEach((x,i)=>{
    a = a.concat(task(i));
  })
  return a;
}
function sum(inArr){
  if (inArr && inArr.length > 0) {
    const add = (a, b) => {
      return a + b  
    }
    return inArr.reduce(add);
  } else {
    return 0;
  }
}
function eachWithIndex(inArr, callback) {
  let i = 0;
  return inArr.forEach((v)=>{
    callback(v, i);
    i++;
  });
}
function mapWithIndex(inArr, callback) {
  let i = -1;
  return inArr.map((v)=>{
    i = i + 1;
    return callback(v, i);
    
  });
}
function insertAtIndex(inArr, index, value) {
  const length = inArr.length;
  if (index === 0) {
    return [value].concat([...inArr]);
  } else if (index >= length) {
    return inArr.concat([value]);
  } else {
    const first = inArr.slice(0, index);
    const last = inArr.slice(index);
    return first.concat([value]).concat(last);
  }
}
function removeEmpty(arr) {
  return arr.filter((v)=>{
    return (v !== undefined) && (v !== null) && (v !== '');
  })
}
function chunk(inArr, chunkSize){
  let index = 0;
  let tempArray = [];
  for (index = 0; index < inArr.length; index += chunkSize) {
    let chunk = inArr.slice(index, index+chunkSize);
    tempArray.push(chunk);
  }
  return tempArray;
}
export default {
  isEqual,
  shuffle,
  sum,
  times,
  mapTimes,
  concatTimes,
  concatFuncTimes,
  uniq,
  uniqBy,
  eachWithIndex,
  mapWithIndex,
  insertAtIndex,
  removeEmpty,
  chunk
}