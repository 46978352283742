import React from 'react';
import {HyperageContext} from '../../contexts/HyperageContext';
import HyperageFanPopup from './HyperageFanPopup';

export default class HyperageFanPopups extends React.Component {

  renderPopups(){
    return this.context.fanPopups.map((popup)=>{
      return (
        <HyperageFanPopup
          key={`p-${popup.id}`}
          popup={popup} />
      );
    })
  }
  render() {
    return (
      <div className='hyperage--fan--popups'>
        {this.renderPopups()}
      </div>
    );
  }
}

HyperageFanPopups.contextType = HyperageContext;