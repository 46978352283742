import React from 'react';

export default class HyperfoodFloaterVotedBest extends React.Component {

  render() {
    return (
      <div className='hyperfood--floater--votedBest'>
        Voted Best
      </div>
    );
  }
}