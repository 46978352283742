import React from 'react';
import PropTypes from 'prop-types';
import Calc from '../../utils/Calc';
import {Rnd} from 'react-rnd';
import {HyperwebContext} from '../../contexts/HyperwebContext';

export default class HyperwebImagePopup extends React.Component {
  handleClick(e){
    this.context.onFocusPopup(this.props.popup);
  }
  handleClose(e) {
    this.context.onClosePopup(this.props.popup);
    e.stopPropagation()
  }
  render() {
    return (
      <Rnd
        className={`hyperweb--imagePopup hyperweb--imagePopup--${this.props.popup.item.key}`}
        default={{
          x: Calc.randomIntBetween(10, window.innerWidth * .9),
          y: Calc.randomIntBetween(10, window.innerHeight * .9)
        }}
        style={{
          zIndex: this.props.popup.zIndex + 100
        }}
        bounds='window'
        // dragHandleClassName='hyperweb--textEditorPopup--toolbar'
        onClick={(e)=>{this.handleClick(e)}}
      >
        <button 
          className='hyperweb--imagePopup--closeBtn'
          onClick={(e)=>{this.handleClose(e)}}>
          X
        </button>
        <img 
          draggable={false}
          src={this.props.popup.item.path}
          alt={this.props.popup.item.fontFamily}
          data-font-family={this.props.popup.item.fontFamily} />
      </Rnd>
    );
  }
}

HyperwebImagePopup.propTypes = {
  popup: PropTypes.object.isRequired
};

HyperwebImagePopup.contextType = HyperwebContext;
