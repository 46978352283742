import React from 'react';
import {HyperfoodContext} from '../../contexts/HyperfoodContext';

export default class HyperfoodCheckoutButton extends React.Component {
  render() {
    return (
      <div 
        className='hyperfood--checkoutButton'
        onClick={()=>{this.context.onSubmit()}}>
        <div className='hyperfood--checkoutButtonText'>
          <span>Submit Order</span>
          <span>Submit Order</span>
        </div>
      </div>
    );
  }
}

HyperfoodCheckoutButton.contextType = HyperfoodContext;