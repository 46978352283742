import React from 'react';
import HyperageHeader from './HyperageHeader';
import HyperageIntro from './HyperageIntro';
import HyperageBreathe from './HyperageBreathe';
import HyperagePalm from './HyperagePalm';
import GlobalNav from '../Global/GlobalNav';
// import HyperageMouseAuraSingle from './HyperageMouseAuraSingle';
import HyperageMouseAuraTrail from './HyperageMouseAuraTrail';
import HyperageHistory from './HyperageHistory';
import HyperageBigBigText from './HyperageBigBigText';
import HyperageBriefHistory from './HyperageBriefHistory';
import HyperageFanPopups from './HyperageFanPopups';
import {HyperageContext, HyperageFanRandomPopups} from '../../contexts/HyperageContext';
import Track from '../../utils/Track';

export default class Hyperage extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      fanPopups: HyperageFanRandomPopups(20, {
        skipAnimate: true
      }),
      onAddFanPopup: (s)=>{this.handleAddFanPopup(s)}
    }
  }
  componentDidMount() {
    Track.pageLoad();
  }
  handleAddFanPopup(skipAnimate, inCount){
    const count = inCount || 1;
    const popups = this.state.fanPopups.concat(
      HyperageFanRandomPopups(count, {
        skipAnimate: skipAnimate
      })
    );
    if (popups.length > 30) {
      popups.shift();
    }
    window.requestIdleCallback(()=>{
      this.setState({
        fanPopups: popups
      });
    });
  }
  render() {
    return (
      <HyperageContext.Provider value={this.state}>
        <div 
          className='hyperage'>
          <HyperageMouseAuraTrail />
          <HyperageFanPopups />
          <GlobalNav>
            <HyperageHeader />
          </GlobalNav>
          
          {true ? 
            <div>
              <HyperageIntro />
              <HyperageBreathe
                key='breathe' 
                theme='breathe' />  
              <HyperageBigBigText />
              <HyperageBriefHistory />
              <HyperageBreathe
                key='fan' 
                theme='fan' />  
              <HyperageHistory />
              <HyperageBreathe
                key='stretch' 
                theme='stretch'
                framesPerCycle={15} />
              
            </div>
          : null }

          <HyperagePalm />

          
        </div>
      </HyperageContext.Provider>
    );
  }
}