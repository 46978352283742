import React from 'react';
import GlobalNavSlideIn from './GlobalNavSlideIn';
import GlobalBackBtn from '../Global/GlobalBackBtn';

export default class GlobalNav extends React.Component {
  renderBlocks() {
    let i = 0;
    let blocks = [];
    while(i < 10) {
      blocks.push(
        <div 
          key={`navBlock-${i}`}
          className='globalNav--block'>
          <span className='globalNav--english'>
            HyperText
          </span>
          <span className='globalNav--korean'>
            하이퍼 텍스트
          </span>
          <span className='globalNav--english'>
            Issue 01
          </span>
          <span className='globalNav--korean'>
            볼륨 하나
          </span>
          <span className='globalNav--english'>
            Future Fonts
          </span>
          <span className='globalNav--korean'>
            미래의 글꼴
          </span>
        </div>
      )
      i+=1;
    }
    return blocks;
  }
  render() {
    return (
      <nav className='globalNav'>
        <GlobalNavSlideIn />
        <div className='globalNav--main'>
          {this.renderBlocks()}
        </div>
        {this.props.children}
        <GlobalBackBtn />
      </nav>
    );
  }
}

// 하이퍼 텍스트 translates to: hypertext 

// 볼륨 하나 translates to: volume(issue) one

// 미래의 글꼴 translates to: future fonts