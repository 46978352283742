import React from 'react';
import PropTypes from 'prop-types';
import {HyperwebContext, HyperwebContextRandomTextEditorString, HyperwebContextRandomTextEditorTheme} from '../../contexts/HyperwebContext';
import {Rnd} from 'react-rnd';
import Calc from '../../utils/Calc';

export default class HyperwebTextEditorPopup extends React.Component {
  constructor(props){
    super(props);
    const randomColor = TOOLBAR_COLORS[Calc.randomIntBetween(0, TOOLBAR_COLORS.length - 1)];

    this.state = {
      textValue: this.props.popup.item.hyperwebDefaultText || HyperwebContextRandomTextEditorString(),
      theme: this.props.popup.item.hyperwebTheme || HyperwebContextRandomTextEditorTheme(),
      toolbarColor: randomColor
      // theme: 'purpleGradient'
    }
  }
  handleTextChange(e) {
    this.setState({
      textValue: e.target.value
    });
  }
  handleClick(e){
    this.context.onFocusPopup(this.props.popup);
  }
  handleClose(e) {
    this.context.onClosePopup(this.props.popup);
    e.stopPropagation()
  }
  render() {
    const toolbarColorClass = `hyperweb--textEditorPopup--toolbar--${this.state.toolbarColor}`;
    return (
      <Rnd
        className={`hyperweb--textEditorPopup hyperweb--textEditorPopup--${this.props.popup.item.fontSlug} hyperweb--textEditorPopup--theme--${this.state.theme}`}
        default={{
          x: Calc.randomIntBetween(10, window.innerWidth * .6),
          y: Calc.randomIntBetween(10, window.innerHeight * .8),
          width: Calc.randomIntBetween(500, 900),
          height: Calc.randomIntBetween(200, 300)
        }}
        style={{
          zIndex: this.props.popup.zIndex + 100
        }}
        bounds='window'
        minHeight={100}
        minWidth={300}
        dragHandleClassName='hyperweb--textEditorPopup--toolbar'
        onClick={(e)=>{this.handleClick(e)}}
      >
        <div className={`hyperweb--textEditorPopup--toolbar ${toolbarColorClass}`}>
          <div className='hyperweb--textEditorPopup--credits'> 
            {this.props.popup.item.fontName} by {this.props.popup.item.foundryName}
          </div>
          <div className='hyperweb--textEditorPopup--controls'>
            <button 
              className='hyperweb--textEditorPopup--closeBtn'
              onClick={(e)=>{this.handleClose(e)}}>
              X
            </button>
          </div>

          <div className='hyperweb--textEditorPopup--toolbarBevel'></div>
        </div>
        <div className='hyperweb--textEditorPopup--content'>
          <textarea
            type='text'
            className={`hyperweb--textEditorPopup--input font--${this.props.popup.item.fontSlug}`}
            value={this.state.textValue}
            onChange={(e)=>{this.handleTextChange(e)}}
            autoFocus={true}
            spellCheck={false} />
        </div>
      </Rnd>
    );
  }
}

HyperwebTextEditorPopup.propTypes = {
  popup: PropTypes.object.isRequired
};

HyperwebTextEditorPopup.contextType = HyperwebContext;

const TOOLBAR_COLORS = [
  'blue',
  'red',
  'orange',
  'pink',
  'green',
  'yellow'
];
