import React from 'react';
import Arr from '../../utils/Arr';

export default class HyperwebHeader extends React.Component {
  renderBlocks(){
    return Arr.concatFuncTimes(10, (i)=>{
      return (
        <span 
          key={`hyerweb-headerBlock-${i}`}
          className='hyperweb--headerBlock'>
          Welcome to HyperWeb
          <span className='hyperweb--headerBlock--spacer'>$€¥£</span>
        </span>
      );
    });
  }
  render() {
    return (
      <div className='hyperweb--header'>
        {this.renderBlocks()}
      </div>
    );
  }
}