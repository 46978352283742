import React from 'react';

export default class HyperwarpStars extends React.Component {

  render() {
    return (
      <div className="hyperwarp--scene">
        <div className="hyperwarp--wrap">
            <div className="hyperwarp--wall hyperwarp--wall--right"></div>
            <div className="hyperwarp--wall hyperwarp--wall--left"></div>   
            <div className="hyperwarp--wall hyperwarp--wall--top"></div>
            <div className="hyperwarp--wall hyperwarp--wall--bottom"></div> 
            <div className="hyperwarp--wall hyperwarp--wall--back"></div>    
        </div>
        <div className="hyperwarp--wrap">
            <div className="hyperwarp--wall hyperwarp--wall--right"></div>
            <div className="hyperwarp--wall hyperwarp--wall--left"></div>   
            <div className="hyperwarp--wall hyperwarp--wall--top"></div>
            <div className="hyperwarp--wall hyperwarp--wall--bottom"></div> 
            <div className="hyperwarp--wall hyperwarp--wall--back"></div>    
        </div>
      </div>
    );
  }
}