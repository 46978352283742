import React from 'react';
import Arr from '../../utils/Arr';

export default class HyperwebScreensaver3dText extends React.Component {
  renderText(){
    return Arr.mapTimes(25, (i)=>{
      return (
        <div 
          key={`3d-${i}`}
          className='hyperweb--screensaver--3dText--layer'>
          HyperWeb 18
        </div>
      );
    })
  }
  render() {
    return (
      <div className='hyperweb--screensaver hyperweb--screensaver--3dText'>
          <div className='hyperweb--screensaver--3dText--artbox'>
            {this.renderText()}
            <div className='hyperweb--screensaver--3dText--top'>HyperWeb 18</div>
          </div>
      </div>
    );
  }
}