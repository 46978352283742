import React from 'react';
import PropTypes from 'prop-types';
import HyperfoodFloaterPlsOrderHere from './HyperfoodFloaterPlsOrderHere';
import HyperfoodFloaterNo1 from './HyperfoodFloaterNo1';
import HyperfoodFloater24hours from './HyperfoodFloater24hours';
import HyperfoodFloaterFries from './HyperfoodFloaterFries';
import HyperfoodFloaterXl from './HyperfoodFloaterXl';
import HyperfoodFloaterDunkel1 from './HyperfoodFloaterDunkel1';
import HyperfoodFloaterDunkel2 from './HyperfoodFloaterDunkel2';
import HyperfoodFloater2018 from './HyperfoodFloater2018';
import HyperfoodFloaterFreshlyBaked from './HyperfoodFloaterFreshlyBaked';
import HyperfoodFloaterBuyNow from './HyperfoodFloaterBuyNow';
import HyperfoodFloaterQuality from './HyperfoodFloaterQuality';
import HyperfoodFloater99cents from './HyperfoodFloater99cents';
import HyperfoodFloaterVotedBest from './HyperfoodFloaterVotedBest';
import HyperfoodFloaterSale from './HyperfoodFloaterSale';
import HyperfoodFloaterYummy from './HyperfoodFloaterYummy';

const HyperfoodFloater = props => {
  const renderFloater = () => {
    switch (props.floater.item.name) {
      case 'plsOrderHere':
        return <HyperfoodFloaterPlsOrderHere />
      case 'no1':
        return <HyperfoodFloaterNo1 />
      case '24hours':
        return <HyperfoodFloater24hours />
      case 'fries':
        return <HyperfoodFloaterFries />
      case 'xl':
        return <HyperfoodFloaterXl />
      case 'dunkel1':
        return <HyperfoodFloaterDunkel1 />
      case 'dunkel2':
        return <HyperfoodFloaterDunkel2 />
      case '2018':
        return <HyperfoodFloater2018 />
      case 'freshlyBaked':
        return <HyperfoodFloaterFreshlyBaked />
      case 'buyNow':
        return <HyperfoodFloaterBuyNow />
      case 'quality':
        return <HyperfoodFloaterQuality />
      case '99cents':
        return <HyperfoodFloater99cents />
      case 'votedBest':
        return <HyperfoodFloaterVotedBest />
      case 'sale':
        return <HyperfoodFloaterSale />
      case 'yummy':
        return <HyperfoodFloaterYummy />
      default:
        return null;
    }
  }
  return (
    <div 
      onClick={props.onItemRemove}
      className={`hyperfood--floater hyperfood--floater--float${props.floater.floatIndex}`}
        style={{
          left: `${props.floater.startX}vw`, 
          top: `${props.floater.startY}vh`
        }}>
      {renderFloater()}
    </div>
  )
};

HyperfoodFloater.propTypes = {
  onItemRemove: PropTypes.func,
  floater: PropTypes.object
};


export default HyperfoodFloater;