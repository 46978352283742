import React from 'react';

export default class HyperageBriefHistory extends React.Component {

  render() {
    return (
      <div className='hyperage--briefHistory'>
        <div className='hyperage--briefHistory--content'>
          <p>
            As the palm leaves crumble in our minds we still remember our birth and beginnings as humankind. We remember the past and collectively imagine the future. Where does our past begin? With the same effort it takes to remember our personal births we remember our collective birth by quilting together all of our memories into one; our history.
          </p>
          <div className='hyperage--briefHistory--pullquote'>
            break the knots
            that bind you to your
            physical existence
          </div>
          <p>
            The beginnings can be traced back to over 5,000 years ago, but some researchers think that we may exist as far as up to 10,000 years old old. Our long rich history can be divided into four main periods of innovation, practice and development.
          </p>
        </div>
      </div>
    );
  }
}