import React from 'react';
import Arr from '../../utils/Arr';

export default class HomeTop extends React.Component {
  renderText() {
    return Arr.mapTimes(30, (i)=>{
      return (
        <span key={`t-${i}`}> Celebrating the first year of releases from Future Fonts * </span>
      );
    })
  }
  render() {
    return (
      <div className='home--top'>
        <div className='home--topBlock'>
          {this.renderText()}
        </div>
      </div>
    );
  }
}