import React from 'react';

export default class HyperfoodFloaterSale extends React.Component {

  render() {
    return (
      <div className='hyperfood--floater--sale'>
        <img 
          src='/images/hyperfood/hyperfood-SaleBurst.svg'
          className='hyperfood--sale--burst'
          alt='' />
        <div className='hyperfood--floater--sale--content'> 
          <span className='hyperfood--floater--sale--text'>SALE!</span><br />
          <span className='hyperfood--floater--sale--underline'>&#x2015;</span>
        </div>
      </div>
    );
  }
}