import React from 'react';
import PropTypes from 'prop-types';
import HyperagePalmOptionsData from '../../data/HyperagePalmOptionsData';
import HyperagePalmOption from './HyperagePalmOption';

export default class HyperagePalmOptions extends React.Component {
  renderOptions() {
    return HyperagePalmOptionsData.all.map((option)=>{
      return (
        <HyperagePalmOption
          key={option.slug}
          option={option}
          onOptionSelect={this.props.onOptionSelect} />
      );
    })
  }
  render() {
    return (
      <div className='hyperage--palm--options'>
        {this.renderOptions()}
      </div>
    );
  }
}

HyperagePalmOptions.propTypes = {
  onOptionSelect: PropTypes.func
};