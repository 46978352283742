import React from 'react';
import PropTypes from 'prop-types';

export default class HyperageBreatheHeader extends React.Component {
  text() {
    switch (this.props.theme) {
      case 'fan':
        return 'Our practices are designed to rejuvenate your body and prolong life.';
      case 'breathe':
        return 'The origin of our path can be traced back to over 5,000 years ago.';
      case 'stretch':
      default:
        return 'Our attention is focused on the development of your physical-spiritual existence.'

    }
  }
  render() {
    return (
      <div className='hyperage--breathe--header'>
        {this.text()}
      </div>
    );
  }
}

HyperageBreatheHeader.propTypes = {
  theme: PropTypes.string
};
