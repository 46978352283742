import React from 'react';
import PropTypes from 'prop-types';

const FontIndexSpacer = props => {
  const midClass = props.isMid ? 'fontIndex--spacer--mid' : '';
  return (
    <div className={`fontIndex--spacer ${midClass}`}>
    </div>
  );
};

FontIndexSpacer.propTypes = {
  isMid: PropTypes.bool
};

export default FontIndexSpacer;