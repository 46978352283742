import React from 'react';
import {HyperfoodContext} from '../../contexts/HyperfoodContext';

export default class HyperfoodEmptySubmitMessage extends React.Component {
  render() {
    return (
      <div className='hyperfood--emptySubmitMessage'>
        <div 
          className='hyperfood--emptySubmitMessage--bgClose'
          onClick={()=>{this.context.onUnSubmit()}}>
        </div>
        <div className='hyperfood--emptySubmitMessage--content'>
          <div className='hyperfood--emptySubmitMessage--header'>
            No items in your cart! 
          </div>
          <div className='hyperfood--emptySubmitMessage--text'>
            Add some tasty treats, and maybe something special will happen when you submit next time.
          </div>
          <div 
            onClick={()=>{this.context.onUnSubmit()}}
            className='hyperfood--emptySubmitMessage--closeBtn'>
            Continue Shopping
          </div>
        </div>
      </div>
    );
  }
}

HyperfoodEmptySubmitMessage.contextType = HyperfoodContext;