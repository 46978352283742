import React from 'react';
import PropTypes from 'prop-types';
import HyperwebAdCoolType from './HyperwebAdCoolType';
import HyperwebAdCookies from './HyperwebAdCookies';
import HyperwebAdCongrats from './HyperwebAdCongrats';
import HyperwebAdHyperfood from './HyperwebAdHyperfood';
import HyperwebAdHyperage from './HyperwebAdHyperage';
import HyperwebAdNeedHelp from './HyperwebAdNeedHelp';

export default class HyperwebAds extends React.Component {
  renderAd() {
    switch(this.props.name) {
      case 'hyperfood':
        return <HyperwebAdHyperfood />
      case 'hyperage':
        return <HyperwebAdHyperage />
      case 'needHelp':
        return <HyperwebAdNeedHelp />
      case 'congrats':
        return <HyperwebAdCongrats />
      case 'cookies':
        return <HyperwebAdCookies />
      case 'coolFonts': 
      default:
        return <HyperwebAdCoolType />
    }
  }
  render() {
    return this.renderAd();
  }
}

HyperwebAds.propTypes = {
  name: PropTypes.string
};
